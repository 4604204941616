import React, {useRef, useState} from 'react';
import {Fade} from "@material-ui/core";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputMask} from "primereact/inputmask";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import api from "../../services/api";
import {useAuth} from "../../contexts/AuthContext";
import LoadingChamados from "../LoadingChamados";



const FirstAccessForm = ({ visible, header, onHide }) => {
    const toast = useRef();
    const { setFirstAccess } = useAuth();
    const [loading, setLoading] = useState(false);

    const [phone, setPhone] = useState("");
    const [office, setOffice] = useState("");
    const [sector, setSector] = useState("");
    const [password, setPassword] = useState("");


    const handleSubmitForm = () => {

        if(!phone || !office || !sector || !password) {
            toast.current.show({ severity: 'warn', summary: '', detail: 'Preencha todas as informações para prosseguir.', life: 5000 })
            return;
        }

        setLoading(true);

        const data = {
            phone,
            office,
            sector,
            password
        }
        api.put('/auth/first_access', data).then(() => {
            setLoading(false);
            setFirstAccess(false);
        }).catch(e => {
            setLoading(false);
            if(e.request.status === 400 || e.request.status === 400) {
                const errors = JSON.parse(e.request.response);
                for (let i in errors) {
                    errors[i].map(error => {
                        toast.current.show({ severity: 'error', summary: '', detail: error, life: 7000 });
                    })
                }

            } else {
                toast.current.show({ severity: 'error', summary: '', detail: 'Ocorreu um erro ao alterar', life: 7000 });
            }
        });

    }

    const footer = (
        <React.Fragment>
            <Button label={"Salvar"} icon="pi pi-check" className="p-button-text" onClick={handleSubmitForm } />
        </React.Fragment>
    );

    const constructCreateChamado = () => {
        return (
            <Fade in={true}>

                <div className="container">



                    <Toast ref={toast} className="p-button-success" />

                    <div className={'row'}>
                        <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
                            <form className={'d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center'} onSubmit={ () => {} }>

                                <div className={'row pb-1'}>

                                    <div className={'col-md-12 pb-3'}>
                                        <label htmlFor="Nº Série / PAT">Telefone </label>
                                        <InputMask className={'mb-2'} mask="(99) 9 9999-9999" value={phone} placeholder="Telefone" onChange={e => setPhone(e.target.value)} />

                                    </div>

                                </div>

                                <div className={'row pb-1'}>
                                    <div className={'col-md-6 pb-3'}>
                                        <label htmlFor="Nº Série / PAT">Cargo </label>
                                        <InputText className={'mb-2'} placeholder="Cargo" id="nomeEmpresa" value={office} onChange={ e => setOffice(e.target.value) } />

                                    </div>

                                    <div className={'col-md-6 pb-3'}>
                                        <label htmlFor="Nº Série / PAT">Setor </label>
                                        <InputText className={'mb-2'} placeholder="Setor" id="nomeEmpresa" value={sector} onChange={ e => setSector(e.target.value) } />

                                    </div>

                                </div>

                                <div className={'col-md-12 pb-3'}>
                                    <label htmlFor="Nº Série / PAT">Nova senha </label>

                                    <Password toggleMask={true} feedback={false} type="password" className={'mt-2 mb-2 p'} placeholder="Nova Senha" value={password}
                                              onChange={e => setPassword(e.target.value)} />

                                    <small>Sua senha deve conter: 8 caracteres, uma letra maiúscula e uma minúscula, um número e um caracter especial</small>

                                </div>


                            </form>
                        </div>

                    </div>
                </div>
            </Fade>
        );
    }
    return (
        <>
            <LoadingChamados load={loading}/>
            <Dialog visible={Boolean(visible)} style={{ marginTop: 60 }} header={header} modal className="p-fluid" footer={footer} onHide={onHide} >

                <div className={'container pt-2'}>
                    {constructCreateChamado()}

                </div>

            </Dialog>
        </>

    );

}

export default FirstAccessForm;
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import ConfirmModal from "../../../components/ConfirmModal";
import { useAuth } from "../../../contexts/AuthContext";
import api from "../../../services/api";
const PendingEquipments = () => {
  const [rows, setRows] = useState(10);
  const { access_type } = useAuth();

  const [productDialog, setProductDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toast = useRef(null);
  const dt = useRef(null);

  let [servico, setServico] = useState("");

  const [equipments, setEquipments] = useState([]);
  const [counter, setCounter] = useState(0);
  const [search, setSearch] = useState("");
  const [is_active, setIsActive] = useState("");

  const [page, setPage] = useState(1);
  const [ordenado, setOrdenado] = useState("");

  useEffect(() => {
    getEquipments().then(() => setLoading(false));
  }, [page, search, ordenado, is_active, rows]);

  const [sortOrder, setSortOrder] = useState(-1);
  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setOrdenado(sortField);
  };

  const getEquipments = async () => {
    setLoading(true);

    await api
      .get(`equipments/getPendingEquipments`)
      .then((response) => {
        setEquipments(response.data);
      })
      .finally(() => setLoading(false));
  };

  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);

  const [marcasOptions, setMarcasOptions] = useState([
    { value: "", label: "" },
    { value: "", label: "" }
  ]);
  const [modeloOptions, setModeloOptions] = useState([
    { value: "", label: "" },
    { value: "", label: "" }
  ]);

  const getModelos = async () => {
    setLoading(true);

    await api
      .get(`models`)
      .then((response) => {
        setModelos(response.data.data);
        modeloOptions.push(
          ...response.data.map((modelo) => ({
            value: modelo.id,
            label: modelo.name
          }))
        );
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const getMarcas = async (load = true) => {
    setLoading(load);

    await api
      .get(`brands`)
      .then((response) => {
        setMarcas(response.data.data);
        marcasOptions.push(
          ...response.data.map((marca) => ({
            value: marca.id,
            label: marca.name
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  const openNew = async (rowData) => {
    document.body.style.position = "fixed";

    if (rowData) {
      setPat(rowData?.pat);
      setSerialNumber(rowData?.serial_number);
      setEquipment(rowData?.equipment);
      setBrandName(rowData.brand?.name);
      setModelName(rowData.model?.name);
      setNomeEmpresa(rowData?.company?.name);
      setModel_id(rowData.model?.id);
      setBrand_id(rowData.brand?.id);
      setIdEmpresa(rowData.company?.id);
      setEditingEquipmentId(rowData.id);
      setType(rowData.type);
      setIsFromCostumer(rowData.is_from_costumer ? "Sim" : "Não");
    }

    setProductDialog(true);
    await getMarcas();
    await getModelos();
    await getCompanies();
  };

  const leftToolbarTemplate = () => {
    return null;
  };
  const [singleEquipment, setSingleEquipment] = useState({});

  const [acceptModal, setAcceptModal] = useState(false);

  const openAcceptModal = (rowData) => {
    setSingleEquipment(rowData);
    setAcceptModal(true);
  };

  const cancelChamado = async () => {
    const id = singleEquipment.id;
    const data = singleEquipment;
    delete data.id;
    data.is_active = false;

    await api.put(`equipments/${id}`, data).then((response) => {
      toast.current.show({
        severity: "success",
        summary: "Cancelado equipamento com sucesso.",
        detail: response.data.response,
        life: 5000
      });
    });

    setAcceptModal(false);
    await getEquipments();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-check-circle"
          tooltip="Aceitar"
          tooltipOptions={{ position: "top" }}
          className="p-button-rounded p-button-success text-white p-mr-2 me-2"
          onClick={() => openNew(rowData)}
        />
        <Button
          icon="pi pi-times-circle"
          tooltip="Cancelar"
          tooltipOptions={{ position: "top" }}
          className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
          onClick={() => openAcceptModal(rowData)}
        />
      </React.Fragment>
    );
  };

  const items = [
    {
      label: "Filtrar",
      items: [
        {
          label: "Ativo",
          command: () => {
            setIsActive(1);
            setPage(1);
          }
        },
        {
          label: "Não Ativo",
          command: () => {
            setIsActive(0);
            setPage(1);
          }
        }
      ]
    }
  ];

  const menu = useRef(null);

  const header = (
    <div className="d-flex flex-row align-content-center justify-content-between">
      <div>
        <h5 className="p-m-0">Equipamentos pendentes</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
          {/*<Menu model={items} popup ref={menu} id="popup_menu" />*/}
          {/*<Button icon="pi pi-filter" className={'ms-2 text-white'} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />*/}
        </span>
      </div>
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleCreateEquipment = async () => {
    if (!brand_id || !model_id || !equipment || !type) {
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Os campos marca, modelo, tipo e equipamento são obrigatórios",
        life: 5000
      });
      return;
    }

    let is_from_costumerData = false;
    if (is_from_costumer === "Sim") {
      is_from_costumerData = true;
    }

    const data = {
      pat,
      serial_number,
      equipment,
      brand_id,
      model_id,
      company_id: id_empresa,
      type,
      is_from_costumer: is_from_costumerData,
      pending_approval: false,
      service_type: servico
    };

    try {
      const toastDetail = is_new ? "Criado" : "Alterado";

      if (is_new) {
        await api.post("/equipments", data);
      } else if (!is_new && access_type === "Admin") {
        await api.put("/equipments/" + editingEquipmentId, data);
      }

      toast.current.show({
        severity: "success",
        summary: "",
        detail: toastDetail + " equipamento com sucesso",
        life: 5000
      });

      await getEquipments();
      onHideDialog();
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Erro ao criar equipamento",
        life: 5000
      });
    }
  };

  const templateSelectPrime = (option) => {
    return <div style={{ maxWidth: "500px" }}>{option.label}</div>;
  };

  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [id_empresa, setIdEmpresa] = useState("");
  const [empresas, setEmpresas] = useState([]);

  const handleChangeSelect = (selectedOption) => {
    setIdEmpresa(selectedOption.value);
    options.forEach((e) => {
      if (e.value === selectedOption.value) {
        setNomeEmpresa(e.label);
        return;
      }
    });
  };

  const [is_from_costumer, setIsFromCostumer] = useState("Não");

  const handleChangeSelectBelongsToCostumer = (selectedOption) => {
    setIsFromCostumer(selectedOption.value);
  };

  const [brand_id, setBrand_id] = useState(0);
  const [model_id, setModel_id] = useState(0);
  const [equipment, setEquipment] = useState("");
  const [pat, setPat] = useState("");
  const [serial_number, setSerialNumber] = useState("");
  const [type, setType] = useState("");
  const [is_new, setIsNew] = useState(false);
  const [editingEquipmentId, setEditingEquipmentId] = useState(0);

  const [modelName, setModelName] = useState("");
  const [brandName, setBrandName] = useState("");
  const handleChangeSelectBrand = (selectedOption) => {
    setBrand_id(selectedOption.value);
    marcasOptions.forEach((e) => {
      if (e.value === selectedOption.value) {
        setBrandName(e.label);
        return;
      }
    });
  };

  const handleChangeSelectModel = (selectedOption) => {
    setModel_id(selectedOption.value);
    modeloOptions.forEach((e) => {
      if (e.value === selectedOption.value) {
        setModelName(e.label);
        return;
      }
    });
  };

  const [options, setOptions] = useState([{ value: "", label: "" }]);
  let alterEmpresa = [];

  const getCompanies = async () => {
    await api
      .get(`companies/all`)
      .then((response) => {
        setEmpresas(response.data);
        alterEmpresa = response.data;
        options.push(
          ...response.data.map((empresa) => ({
            value: empresa.id,
            label: empresa.name
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  const onHideDialog = () => {
    setProductDialog(false);

    setPat("");
    setSerialNumber("");
    setEquipment("");
    setBrand_id(null);
    setBrandName("");
    setModel_id(null);
    setModelName("");
    setNomeEmpresa("");
    setIdEmpresa(null);
    setIsNew(false);
    setType("");
    setEditingEquipmentId(0);
    setIsFromCostumer("Não");
    document.body.style.position = null;
  };

  const userDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHideDialog}
      />
      <Button
        label="Aceitar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleCreateEquipment}
      />
    </React.Fragment>
  );

  const handleChangeServico = (option) => {
    setServico(option.value);
  };

  const servicoArr = [
    "",
    "Entrega Técnica",
    "Em Garantia",
    "Contrato / Frota",
    "A Faturar"
  ];

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast ref={toast} position="bottom-right" />

        <div className="card">
          <DataTable
            ref={dt}
            value={equipments}
            dataKey="id"
            rows={rows}
            header={header}
            className={"p-datatable-responsive-demo"}
            loading={loading}
            selectionMode="single"
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column field="pat" header={"PAT"} />
            <Column field="serial_number" header="Número de série" />
            <Column field="brand.name" header="Marca" />
            <Column field="model.name" header="Modelo" />
            <Column field="equipment" header="Equipamento" />
            <Column field="company.name" header="Empresa" />
            {access_type === "Admin" && (
              <Column header="Ações" body={actionBodyTemplate} />
            )}
          </DataTable>
        </div>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: 700, marginTop: 50 }}
        modal
        footer={userDialogFooter}
        onHide={onHideDialog}
      >
        <div className={"d-flex flex-column"}>
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
                onSubmit={() => {}}
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Equipamento (Descrição): </label>
                    <InputText
                      value={equipment}
                      onChange={(e) => setEquipment(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">PAT: </label>
                    <InputText
                      value={pat}
                      onChange={(e) => setPat(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Número de série: </label>
                    <InputText
                      value={serial_number}
                      onChange={(e) => setSerialNumber(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Tipo: </label>
                    <InputText
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-6 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Marca: </label>
                    <Dropdown
                      value={brandName}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={brandName}
                      options={marcasOptions}
                      onChange={handleChangeSelectBrand}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione a marca"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-6 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Modelo: </label>
                    <Dropdown
                      value={modelName}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={modelName}
                      options={modeloOptions}
                      onChange={handleChangeSelectModel}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione o modelo"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">
                      {access_type !== "Costumer"
                        ? "Equipamento pertence ao cliente?"
                        : "Equipamento pertence a sua empresa?"}
                    </label>
                    <Dropdown
                      value={is_from_costumer}
                      options={["Sim", "Não"]}
                      onChange={handleChangeSelectBelongsToCostumer}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">
                      Empresa (em que a maquina está - se estiver na sua empresa
                      deixe vazio){" "}
                    </label>
                    <Dropdown
                      value={nomeEmpresa}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={nomeEmpresa}
                      options={options}
                      onChange={handleChangeSelect}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione a empresa"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Servico">Serviço:</label>

                    <Dropdown
                      value={servico}
                      options={servicoArr}
                      onChange={handleChangeServico}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>

      <ConfirmModal
        type={"reject"}
        visible={acceptModal}
        onHide={() => setAcceptModal(false)}
        refused={() => setAcceptModal(false)}
        accepted={cancelChamado}
        label={"Deseja excluir esse equipamento?"}
        extra={"Se cancelar esse equipamento, não poderá ser desfeito."}
      />
    </>
  );
};

export default PendingEquipments;

import {
  Marker,
  useJsApiLoader,
  GoogleMap,
  InfoWindow
} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
const center = { lat: -19.8622544, lng: -43.9994161 };
const zoomThreshold = 15; // Adjust this threshold as needed

var rad = function (x) {
  return (x * Math.PI) / 180;
};

var getDistance = function (p1, p2) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(p2.lat() - p1.lat());
  var dLong = rad(p2.lng() - p1.lng());
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat())) *
      Math.cos(rad(p2.lat())) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

function removeDuplicates(arr) {
  const uniqueCoordinates = [];

  arr.forEach((item) => {
    if (
      !uniqueCoordinates.find(
        (c) =>
          c.address.latitude === item.address.latitude &&
          c.address.longitude === item.address.longitude
      )
    ) {
      uniqueCoordinates.push(item);
    }
  });
  return uniqueCoordinates;
}

export default function CompanyMap() {
  const { getUserLocation, setUserLocation } = useAuth();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAr9hHMg3qjZPkpHTL31nYuzUpu3bYAdlI"
  });

  const userLocation = getUserLocation();

  const [companies, setCompanies] = useState([]);

  const [selectedEmpresa, setSelectedEmpresa] = useState(null);
  const [dropdownCompanies, setDropdownCompanies] = useState([]);

  const getCompanies = async () => {
    const response = await api.get("/companies/getCompaniesWithAddress");
    setCompanies(removeDuplicates(response.data));
    setDropdownCompanies(
      response.data.map(({ id, name }) => ({ value: id, label: name }))
    );
  };

  function calcDistanceFromCompanyToGrupoMOV(company) {
    const companylATLNG = new google.maps.LatLng(
      parseFloat(company?.address?.latitude ?? 0),
      parseFloat(company?.address?.longitude ?? 0)
    );

    return getDistance(
      companylATLNG,
      new google.maps.LatLng(center.lat, center.lng)
    );
  }

  const [map, setMap] = useState(null);

  const onMapLoad = (map) => {
    setMap(map);
  };

  const onChangeCompany = (e) => {
    const company = companies.find((c) => c.id === e.value);
    setCenter({
      lat: parseFloat(company.address.latitude),
      lng: parseFloat(company.address.longitude)
    });
    setSelectedEmpresa(e.value);
  };

  const [center, setCenter] = useState(
    userLocation?.lat && userLocation?.lng
      ? userLocation
      : {
          lat: -19.8622544,
          lng: -43.9994161
        }
  );

  useEffect(() => {
    getCompanies();
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setUserLocation(
        JSON.stringify({ lat: coords.latitude, lng: coords.longitude })
      );
    });
  }, []);
  const [currentZoom, setCurrentZoom] = useState(zoomThreshold);

  useEffect(() => {
    if (map) {
      map.addListener("zoom_changed", () => {
        setCurrentZoom(map.getZoom());
      });
    }
  }, [map]);

  const [selectedCenter, setSelectedCenter] = useState(null);

  if (!isLoaded) return <h1>Carregando...</h1>;

  return (
    <div className="map">
      <h1 className="mb-5">Mapa das empresas cadastradas</h1>
      <div>
        <span>Selecione uma empresa para centralizar</span>
        <div className="d-flex flex-row gap-2 align-items-center justify-content-center mb-2">
          <Dropdown
            value={selectedEmpresa}
            options={dropdownCompanies}
            onChange={onChangeCompany}
            filter
            optionLabel={"label"}
            optionValue={"value"}
            style={{ width: "100%" }}
          />
          <Button
            label="Meu Local"
            icon="pi pi-map-marker"
            className="p-button-text text-white"
            style={{
              background: "#bbbb",
              fontWeight: "bold",
              maxWidth: "100%"
            }}
            onClick={() => {
              setCenter(userLocation);
              setSelectedEmpresa(null);
            }}
          />
          <Button
            label="Grupo MOV"
            icon="pi pi-map-marker"
            className="p-button-text text-white"
            style={{
              background: "#F3C843",
              fontWeight: "bold",
              maxWidth: "100%"
            }}
            onClick={() => {
              setCenter({
                lat: -19.8622544,
                lng: -43.9994161
              });
              setSelectedEmpresa(null);
            }}
          />
        </div>
      </div>

      <GoogleMap
        zoom={zoomThreshold}
        mapContainerStyle={{ width: "100%", height: "100%" }}
        center={center}
        onLoad={onMapLoad}
        options={{
          zoomControl: false,
          disableDefaultUI: true,
          styles: [
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "poi",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            }
          ]
        }}
      >
        {userLocation && userLocation.lat && userLocation.lng && (
          <Marker
            key={Math.random()}
            position={userLocation}
            options={{
              label: {
                text: "Você",
                className: "map-marker"
              },
              icon: {
                path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
                fillOpacity: 1,
                strokeWeight: 1.8,
                labelOrigin: { x: 0, y: -30 }
              }
            }}
          />
        )}

        <Marker
          position={{
            lat: -19.8622544,
            lng: -43.9994161
          }}
          options={{
            label: {
              text: "Grupo MOV - Showroom",
              className: "map-marker"
            },
            icon: {
              path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
              fillOpacity: 1,
              strokeWeight: 1.8,
              labelOrigin: { x: 0, y: -30 },
              fillColor: "#F3C843"
            }
          }}
        />
        {selectedCenter && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedCenter(null);
            }}
            options={{
              pixelOffset: new google.maps.Size(0, -35)
            }}
            position={{
              lat: parseFloat(selectedCenter.address.latitude),
              lng: parseFloat(selectedCenter.address.longitude)
            }}
          >
            <span>
              há{" "}
              {Math.ceil(
                calcDistanceFromCompanyToGrupoMOV(selectedCenter) / 1000
              )}
              km de GrupoMOV
            </span>
          </InfoWindow>
        )}

        {currentZoom >= zoomThreshold &&
          companies.map((company) => {
            return (
              <Marker
                id={company.id}
                position={{
                  lat: parseFloat(company?.address?.latitude),
                  lng: parseFloat(company?.address?.longitude)
                }}
                style={{ position: "relative", zIndex: 999 }}
                onClick={() => setSelectedCenter(company)}
                options={{
                  label: {
                    text: company.name,
                    className: "map-marker"
                  }
                }}
              />
            );
          })}
      </GoogleMap>
    </div>
  );
}

import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import ContextTable from "../../../components/ContextTable";
import { withLogsContext } from "../../../contexts/LogsContext/withLogs";
import { useLogs } from "../../../contexts/LogsContext";
import {
  dataConstruct,
  tableData
} from "../../../contexts/LogsContext/ConstData";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const LogsTable = () => {
  const context = useLogs();

  const [openModal, setOpenModal] = useState(false);
  const toast = useRef(null);
  let { user_id } = useParams();

  const location = useLocation();
  const user = location.state.user;
  context.title = `Logs do usuário - ${user.name}`;
  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.payload && (
          <Button
            tooltip="Copiar payload"
            tooltipOptions={{ position: "top" }}
            icon="pi pi-copy"
            style={{
              backgroundColor: "light-blue",
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button text-white"
            onClick={() => navigator.clipboard.writeText(rowData.payload)}
          />
        )}
        <Button
          tooltip="Mais informações"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-info"
          style={{
            backgroundColor: "#89CFF0",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            setOpenModal(true);
            context.setSelectedItem(rowData);
          }}
        />
      </React.Fragment>
    );
  };

  tableData.find((data) => data.header === "Ações").body = actionTemplate;

  return (
    <div>
      <Toast style={{ zIndex: 1101 }} ref={toast} />
      <Dialog
        visible={openModal}
        style={{ marginTop: 60, width: "80rem" }}
        header={"Mais informações"}
        modal
        className="p-fluid"
        onHide={() => {
          setOpenModal(false);
          context.setSelectedItem({});
        }}
      >
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-row gap-2">
            <strong>Rota:</strong>
            <span>
              {context?.selectedItem?.route}
              {context?.selectedItem?.http_method
                ? ` - ${context?.selectedItem?.http_method}`
                : ""}
            </span>
          </div>
          <div className="d-flex flex-row gap-2">
            <strong>Data e hora:</strong>
            <span>{dataConstruct(context?.selectedItem)}</span>
          </div>
          <div className="d-flex flex-row gap-2">
            <strong>Descrição:</strong>
            <span>{context?.selectedItem.description}</span>
          </div>
          <div className="d-flex flex-row gap-2">
            <strong>IP:</strong>
            <span>{context?.selectedItem.sent_ip}</span>
          </div>
          <div className="d-flex flex-row gap-2">
            <strong>ID:</strong>
            <span>{context?.selectedItem.id}</span>
          </div>
          <div className="d-flex flex-row gap-2">
            <strong>Payload:</strong>
            <span style={{ wordBreak: "break-all" }}>
              {context?.selectedItem?.payload}
            </span>
          </div>
        </div>
      </Dialog>
      <ContextTable
        context={context}
        id={user_id}
        defaultOrderBy="created_at"
      />
    </div>
  );
};

export default withLogsContext(LogsTable);

import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import api from "../../services/api";

export default function ServiceCallFilters({
  isVisible = true,
  onHide = () => {},
  setMultiEmpresas,
  setMultiTechs,
  empresas,
  tecnicos,
  maquinaParada,
  setMaquinaParada,
  setMultiEquips
}) {
  const [selectedTechs, setSelectedTechs] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedMaquinaParada, setSelectedMaquinaParada] = useState("");

  const [equipments, setEquipments] = useState([]);
  const getEquipments = async () => {
    const response = await api.get("/equipments/all");
    console.log("r", response);
    setEquipments(response.data);
  };

  useEffect(() => {
    getEquipments();
  }, []);

  const setValues = () => {
    setMultiEmpresas(selectedCompanies);
    setMultiTechs(selectedTechs);
    setMaquinaParada(selectedMaquinaParada);
    setMultiEquips(selectedEquipments);
    onHide();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          onHide();
          setSelectedCompanies([]);
          setSelectedTechs([]);
        }}
      />
      <Button
        label={"Aplicar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={setValues}
      />
    </React.Fragment>
  );

  const getOptions = (array) => {
    return array.map((item) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  };

  return (
    <Dialog
      header={"Filtros"}
      visible={isVisible}
      style={{ width: "50vw" }}
      footer={footer}
      onHide={() => {
        onHide();
        setSelectedCompanies([]);
        setSelectedTechs([]);
        setSelectedEquipments([]);
        document.body.style.position = null;
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        Selecione as empresas
      </p>
      <MultiSelect
        value={selectedCompanies}
        style={{ width: "100%" }}
        options={getOptions(empresas)}
        display="chip"
        filter
        onChange={(e) => setSelectedCompanies(e.value)}
      />

      <p style={{ fontWeight: "bold", fontSize: "18px", marginTop: 24 }}>
        Selecione os tecnicos
      </p>
      <MultiSelect
        value={selectedTechs}
        style={{ width: "100%" }}
        options={getOptions(tecnicos)}
        display="chip"
        filter
        onChange={(e) => setSelectedTechs(e.value)}
      />

      <p style={{ fontWeight: "bold", fontSize: "18px", marginTop: 24 }}>
        Selecione os equipmamento
      </p>
      <MultiSelect
        value={selectedEquipments}
        style={{ width: "100%" }}
        options={equipments.map((item) => {
          return {
            label:
              (item.pat || "Sem PAT") + " - " + (item.serial_number || "S/N"),
            value: item.id
          };
        })}
        display="chip"
        filter
        onChange={(e) => setSelectedEquipments(e.value)}
      />

      <p style={{ fontWeight: "bold", fontSize: "18px", marginTop: 24 }}>
        Máquina parada?
      </p>
      <Dropdown
        value={selectedMaquinaParada}
        options={["", "Sim", "Nao"]}
        onChange={(option) => setSelectedMaquinaParada(option.value)}
        style={{ height: "50px", alignItems: "center", width: "100%" }}
      />
    </Dialog>
  );
}

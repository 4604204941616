import Grow from "@material-ui/core/Grow";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useState } from "react";
import masks from "../../utils/masks";

export default function NewEmpresa({
  name,
  setName,
  telefone,
  setTelefone,
  email,
  setEmail,
  cnpj,
  setCNPJ,
  nomeContato,
  setNomeContato,
  header,
  footer,
  onHide,
  visible,
  cep,
  setCep,
  pais,
  setPais,
  cidade,
  setCidade,
  estado,
  setEstado,
  rua,
  setRua,
  numero,
  setNumero,
  error,
  nomeFantasia,
  setNomeFantasia,
  bairro,
  setBairro,
  setCallCepApi
}) {
  const setPhoneMask = (phone) => {
    let maskPhone = masks.phone(phone);
    setTelefone(maskPhone);
  };

  const [isPF, setIsPF] = useState(false);

  const onHide2 = () => {
    setSearch(false);
    onHide();
  };

  const [search, setSearch] = useState(false);

  const getCompanyFromApi = async (cnpj) => {
    setCNPJ(cnpj);
    if (!isPF) return;

    let cnpjWithoutMask = cnpj?.replace(/\D/g, "");

    if (cnpjWithoutMask && cnpjWithoutMask.length === 14) {
      setSearch(true);

      $.ajax({
        url: "https://www.receitaws.com.br/v1/cnpj/" + cnpjWithoutMask,

        type: "GET",
        crossDomain: true,
        dataType: "jsonp",
        success: (data) => {
          setName(data.nome);
          setNomeFantasia(data.fantasia);
          setCep(data.cep);
          setEmail(data.email);
          setTelefone(data.telefone);
          setSearch(false);
          setCallCepApi(true);
        },

        error: (e) => {
          setSearch(false);
          console.error(e);
        },

        finally: () => setSearch(false)
      });
    }
  };

  return (
    <Dialog
      visible={visible}
      header={header}
      modal
      className="p-fluid"
      style={{ marginTop: 60 }}
      footer={footer}
      onHide={onHide2}
    >
      <div className="container">
        <div className={"row"}>
          <div
            className={
              "col-md-12 d-flex justify-content-sm-center align-items-center"
            }
          >
            <Grow in={true} timeout={1000}>
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-5 justify-content-center align-content-center"
                }
                style={{ background: "#fefefe" }}
              >
                <div className="d-flex flex-row gap-5 pt-2 pb-2">
                  <span>É pessoa física?</span>
                  <div className="d-flex flex-row items-center gap-2">
                    <span>Sim</span>
                    <RadioButton
                      value={isPF}
                      name="isPFTrue"
                      onChange={(e) => setIsPF(true)}
                      checked={isPF}
                    />
                  </div>
                  <div className="d-flex flex-row items-center gap-2">
                    <span>Não</span>
                    <RadioButton
                      value={isPF}
                      name="isPFFalse"
                      onChange={(e) => setIsPF(false)}
                      checked={!isPF}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column mt-2">
                  <div className={"p-field"}>
                    <InputText
                      disabled={search}
                      className={
                        error === "empresa"
                          ? "p-invalid p-d-block mb-2"
                          : "mb-2"
                      }
                      placeholder={isPF ? "Nome da pessoa" : "Nome da empresa"}
                      id="nomeEmpresa"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  {!isPF && (
                    <div className={"p-field"}>
                      <InputText
                        disabled={search}
                        className={
                          error === "empresa"
                            ? "p-invalid p-d-block mb-2"
                            : "mb-2"
                        }
                        placeholder="Nome Fantasia"
                        id="nomeFantasia"
                        value={nomeFantasia}
                        onChange={(e) => setNomeFantasia(e.target.value)}
                      />
                    </div>
                  )}

                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <InputText
                        disabled={search}
                        className={
                          error === "telefone"
                            ? "p-invalid p-d-block mb-2"
                            : "mb-2"
                        }
                        placeholder="Telefone"
                        id="telefone"
                        maxLength={15}
                        value={telefone}
                        onChange={(e) => setPhoneMask(e.target.value)}
                      />
                    </div>
                    <div className={"col-md-6"}>
                      <InputText
                        disabled={search}
                        className={
                          error === "email"
                            ? "p-invalid p-d-block mb-2"
                            : "mb-2"
                        }
                        placeholder="E-mail"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <InputMask
                    mask={isPF ? "999.999.999-99" : "99.999.999/9999-99"}
                    className={
                      error === "cnpj" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder={isPF ? "CPF" : "CNPJ"}
                    id="cpnj"
                    value={cnpj}
                    onChange={(e) => getCompanyFromApi(e.target.value)}
                  />
                  <InputText
                    disabled={search}
                    className={
                      error === "nomeContato"
                        ? "p-invalid p-d-block mb-2"
                        : "mb-2"
                    }
                    placeholder="Nome Contato"
                    id="NomeContato"
                    value={nomeContato}
                    onChange={(e) => setNomeContato(e.target.value)}
                  />
                  <span className="p-input-icon-right">
                    <InputMask
                      mask="99999-999"
                      className={
                        error === "cep" ? "p-invalid p-d-block mb-2" : "mb-2"
                      }
                      placeholder="CEP"
                      value={cep}
                      onChange={(e) => {
                        setCep(e.target.value);
                      }}
                    />
                  </span>

                  <InputText
                    disabled={search}
                    className={
                      error === "pais" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder="País"
                    value={pais}
                    onChange={(e) => setPais(e.target.value)}
                  />
                  <InputText
                    disabled={search}
                    className={
                      error === "estado" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder="Estado"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                  />
                  <InputText
                    disabled={search}
                    className={
                      error === "bairro" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder="Bairro"
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                  />
                  <InputText
                    disabled={search}
                    className={
                      error === "cidade" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder="Cidade"
                    value={cidade}
                    onChange={(e) => setCidade(e.target.value)}
                  />
                  <InputText
                    disabled={search}
                    className={
                      error === "rua" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder="Rua"
                    value={rua}
                    onChange={(e) => setRua(e.target.value)}
                  />
                  <InputText
                    disabled={search}
                    className={
                      error === "numero" ? "p-invalid p-d-block mb-2" : "mb-2"
                    }
                    placeholder="Número"
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  />
                </div>
              </form>
            </Grow>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

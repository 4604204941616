import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi'
import Loading from "../../components/Loading";
import Grow from '@material-ui/core/Grow';
import './styles.css';
import { Toast } from 'primereact/toast';
import grupomov from '../../assets/Grupo Mov.png';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';

import api from '../../services/api';
import {InputMask} from "primereact/inputmask";

export default function Cadastro() {
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');
    let [confirmarSenha, setConfirmarSenha] = useState('');
    let [nomeEmpresa, setNomeEmpresa] = useState('');
    let [setor, setSetor] = useState('');
    let [cargo, setCargo] = useState('');
    const [telefone, setTelefone] = useState('');

	let [load, setLoad] = useState(false);

    const toast = useRef(null);

    const history = useHistory();


    async function handleRegister(e) {
        e.preventDefault();


        if(name.length === 0 || email.length === 0 || password.length === 0 || confirmarSenha.length === 0 || nomeEmpresa.length === 0 || setor.length === 0 || cargo.length === 0) {
            toast.current.show({severity:'warn', summary: 'Aviso', detail:'Preencha todos os campos', life: 5000});

            return;
        }

        if(password !== confirmarSenha) {
            toast.current.show({severity:'warn', summary: 'Aviso', detail:'As senhas não confirmam', life: 5000});

            return;
        }
		name = name.trim();
		email = email.trim();
		setor = setor.trim();
		cargo = cargo.trim();
		nomeEmpresa = nomeEmpresa.trim();

        const data = {
            name,
            email,
            password,
            sector: setor,
            office: cargo,
            company_name: nomeEmpresa,
            phone: telefone
        };
		setLoad(true);
        setTimeout(async () => {
            let error = false;


            if(!error) {
                try {
                    await api.post('register', data);
                    toast.current.show({severity:'success', summary: 'Sucesso', detail:'Cadastrado com sucesso', life: 5000});

                    history.push('/login?register=200');
                    
    
                } catch (err) {

                    setLoad(false);
                    toast.current.show({severity:'error', summary: 'Error', detail:'E-mail já existe', life: 5000});
                } finally {
                    setLoad(false);
                }
            }

           

        }, 2000);


    }

    return (
        <>
            { load ? <Loading /> : null }
                <div className="cadastro-container">
                <Toast ref={toast} className="p-button-success" />
                    <div className="container h-100">
                        <div className={'row h-100'}>
                            <div className={'col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center h-100'}>
                                <Grow in={true} timeout={1000}>
                                    <form className={'d-flex flex-column col-md-6 shadow rounded p-sm-5 justify-content-center align-content-center'}
                                        onSubmit={handleRegister}
                                        style={{ padding: 80, background: '#fefefe' }}
                                    >

                                        <img src={grupomov} alt="Grupo mov" style={{ width: '12em' }} className="img-fluid mb-5 mx-auto"/>

                                        <div className="d-flex flex-column mt-2">


                                            <InputText placeholder="Nome Completo" id="nomeCompleto" value={name} className={'mt-2 p'} onChange={ e => setName(e.target.value) } />


                                            <InputText placeholder="E-mail" type="email" value={email} className={'mt-2 p'} onChange={ e => setEmail(e.target.value) }/>

                                            <Password value={password} feedback={false} placeholder="Senha" toggleMask={true} className={'mt-2 p'} onChange={ e => setPassword(e.target.value) } />
                                            
                                            <Password toggleMask={true} feedback={false} placeholder="Confirmar senha" value={confirmarSenha} className={'mt-2 p'} onChange={ e => setConfirmarSenha(e.target.value) } />

                                            <InputText placeholder="Nome da sua empresa"  value={nomeEmpresa} className={'mt-2 p'} onChange={ e => setNomeEmpresa(e.target.value) }/>

                                            <InputMask mask="(99) 9 9999-9999" className={'mt-2 p'} value={telefone} placeholder="Celular" onChange={e => setTelefone(e.target.value)} />

                                            <InputText placeholder="Setor" value={setor} className={'mt-2 p'} onChange={ e => setSetor(e.target.value) } />

                                            <InputText placeholder="Cargo" value={cargo} className={'mt-2 p'} onChange={ e => setCargo(e.target.value) }/>

                                        </div>
                                        <div className="btnLoginPage mt-4 d-flex flex-column align-items-center">
                                            <button className="button" type="submit">Cadastrar</button>
                                            <Link to="/login" className="alter mt-2">
                                                Voltar para login
                                            </Link>
                                        </div>
                                    </form>
                                </Grow>
                            </div>

                        </div>
                    </div>
                </div>
        </>
    );
}
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import CreateChamadosForm from "../CreateChamadosForm";

import {Badge} from "primereact/badge";
import Pagination from "@material-ui/lab/Pagination";
import {Calendar} from "primereact/calendar";
import {Menu} from "primereact/menu";
import {useAuth} from "../../contexts/AuthContext";
import ConfirmModal from "../ConfirmModal";
import masks from "../../utils/masks";
import {Dropdown} from "primereact/dropdown";
import DateRange from "../ToolbarComponents/DateRange";
import RowsDropdown from "../RowsDropdown";
import QuantityCard from "../QuantityCard";
import {Skeleton} from "primereact/skeleton";
import Push from "push.js";
import { useHistory } from 'react-router';
import api from "../../services/api";

const ChamadosTable = ({loading, setLoading, route = 'service_calls/all', handleDelete = () => {}, handleEdit = () => {}}) => {
    const dt = useRef(null);
    const menu = useRef(null);
    const { access_type } = useAuth();
    const toast = useRef(null);

    const [toastOptions, setToastOptions] = useState({});
    const [singleChamado, setSingleChamado] = useState({});
    const [productDialog, setProductDialog] = useState(false);
    const [acceptModal, setAcceptModal] = useState(false);
    const [singleChamadoModal, setSingleChamadoModal] = useState(false);

    async function handleShareChamados(chamado) {

        if(!chamado) {
            toast.current.show({severity: 'error', summary: '', detail: 'Não foi possivel compartilhar esse chamado', life: 5000})
            return;
        }
        setLoading(true);
        const year = chamado?.service_date?.split("-")[0];
        const number = year + '.' + chamado.service_number;

        let message = `*GRUPO MOV - INFORMAÇÕES*%0a%0a*Chamado Nº.:* ${number}%0A*Data:* ${chamado.service_date ? dataConstruct(chamado.service_date) : ''}%0a*Cliente:* ${chamado?.company?.name ? chamado?.company?.name : '' }%0a*Marca/Modelo:* ${chamado?.equipment?.brand?.name + ' / ' + chamado?.equipment?.model?.name}%0a*SN / PAT:* ${chamado?.equipment?.serial_number + ' / ' + chamado?.equipment?.pat}%0a*Descrição do problema:* ${chamado.error_description}`
        for(let i = 0; i < 5; i++) {
            message = message.replace('undefined', '');
        }

        let addressUrl = process.env.REACT_APP_LARAVEL_APP + 'api/address?cnpj=' + chamado.company.cnpj;

        message += '%0a*Endereço:* ' + addressUrl;

        const url = "https://api.whatsapp.com/send?&text=" + message;
        setLoading(false);
        window.open(url, "Compartilhamento");
    }


    const showSingleChamadoModal = (rowData) => {
        setSingleChamado(rowData);
        setSingleChamadoModal(true);
        document.body.style.position = 'fixed';

    }

    const openNew = (rowData = null) => {

        setProductDialog(true);
        document.body.style.position = 'fixed';
        setSingleChamado({});
        if(rowData) {
            rowData.edited = true;
            setSingleChamado(rowData);
        }
    }

    const [chamados, setChamados] = useState([]);

    const [beginDate, setBeginDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [inputBeginDate, setInputBeginDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [inputEndDate, setInputEndDate] = useState(new Date(new Date().getFullYear(), 11, 31));
    const [counter, setCounter] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [ordenado, setOrdenado] = useState("");
    const [status, setStatus] = useState('');
    const [rows, setRows] = useState(10);

    const getChamados = async () => {
        setLoading(true);
        await api.get(`${route}page=${page}&search=${search}&orderBy=${ordenado}&beginDate=${beginDate}&endDate=${endDate}&status=${status}&rows=${rows}`)
            .then(response => {
                setChamados(response.data.data);
                setCounter(response.data.total);
                setLoading(false);
            });
    }

    useEffect( () => {

        getChamados();

    }, [page, search, ordenado, status, access_type, rows, beginDate, endDate]);

    const hideDialog = () => {
        setSingleChamado({});
        setProductDialog(false);
        document.body.style.position = null;

    }

    const exportCSV = async () => {
        setLoading(true);
        api.get(`/service_calls/export/excel?beginDate=${beginDate}&endDate=${endDate}`, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'chamados-' + new Date().getTime() + '.xlsx');
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            setLoading(false);

        });

        
    }

    function dataConstruct(data) {

        if(!data) {
            return "";
        }

        let splitT = data.split("T");
        let split = splitT[0].split("-");
        return (split[2] + "/" + split[1] + "/" + split[0]);

    }

  

    const cancelChamado = async () => {
        await api.post(`service_calls/cancel/${singleChamado.id}`).then(response => {
            toast.current.show({severity:'success', summary: '', detail: response.data.response, life: 5000});
        });

        setSingleChamado({});
        setAcceptModal(false);
        await getChamados();
    }

    const downloadReport = async (rowData) => {
        setLoading(true);

        api.get('/report/corrective/pdf/' + rowData.corrective.id, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', rowData.corrective.report + '.pdf');
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            setLoading(false);

        });

    }

    const renderServiceNumber = (rowData) => {
        const year = rowData?.service_date?.split("-")[0];
        return year + '.' + rowData.service_number;
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" style={{ backgroundColor: '#0A073B', outline: 0, border: 'none' }} icon="pi pi-plus" className="p-button-success" onClick={() => openNew(null)} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" style={{ backgroundColor: '#0A073B', outline: 0, border: 'none' }} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const techBodyTemplate = (rowData) => {
        if(!rowData?.tech?.name) {
            return <span style={{ color: 'red' }}>{'Sem técnico'}</span>
        }

        return rowData.tech.name;
    }

    const statusBodyTemplate = (rowData) => {

        if(rowData.status === 'Aberto') {

            return <Badge style={{minWidth: 100, backgroundColor: '#9E3131', color: 'white'}} value={rowData.status}/>;

        } else if(rowData.status === 'Em andamento') {

            return <Badge style={{ minWidth: 100, color: 'white' }} severity={'warning'} value={rowData.status}/>;

        } else if(rowData.status === 'Fechado') {

            return <Badge style={{ minWidth: 100, backgroundColor: '#003B17', color: 'white' }} value={rowData.status}/>;
        }
        return <Badge style={{ minWidth: 100, backgroundColor: 'gray', color: 'white' }} value={rowData.status}/>;

    }

    const handleChangePage = async (event, newPage) => {

        setPage(newPage);

    };

    const actionBodyTemplate = (rowData) => {
       
        return (
            <React.Fragment>
                { access_type === 'Admin' && <Button tooltip="Editar" tooltipOptions={{position: 'top'}}  icon="pi pi-pencil" className="p-button-rounded p-button-warning text-white p-mr-2 me-2" onClick={() => openNew(rowData)} /> }
                <Button icon="pi pi-info" tooltip="Informações" tooltipOptions={{position: 'top'}} style={{ backgroundColor: '#0A073B', outline: 0, border: 'none' }} className="p-button-rounded p-button text-white" onClick={() =>{ rowData.show = true; showSingleChamadoModal(rowData) } } />
                { rowData.equipment && rowData.pending_approval === 0 && <Button tooltip="Compartilhar" tooltipOptions={{position: 'top'}} icon="pi pi-comments" style={{ backgroundColor: '#095009', outline: 0, border: 'none', marginLeft: 6 }} className="p-button-rounded p-button text-white" onClick={() => handleShareChamados(rowData)} /> }
                { rowData.pending_approval === 1 && <Button icon="pi pi-times-circle" tooltip="Cancelar" tooltipOptions={{position: 'top'}} style={{ outline: 0, border: 'none', marginLeft: 6 }} className="p-button-rounded p-button-danger text-white p-mr-2 me-2" onClick={() => openAcceptModal(rowData)} /> }
                { rowData.corrective_count > 0 && <Button  tooltip="Download corretiva" tooltipOptions={{position: 'top'}} icon="pi pi-file-pdf" style={{ backgroundColor: '#7f0b0b', outline: 0, border: 'none', marginLeft: 6 }} className="p-button-rounded p-button text-white" onClick={() => downloadReport(rowData)} /> }
            </React.Fragment>
        );
    }

    const items = [
        {
            label: 'Ordenar',
            items: [
                {
                    label: 'Nº Chamados',
                    command: () => {
                        setOrdenado('service_number');

                    }
                },
                {
                    label: 'Data',
                    command: () => {
                        setOrdenado('service_date');

                    }
                },
                {
                    label: 'Status',
                    command: () => {
                        setOrdenado('status');

                    }
                }

            ]
        },
    ];

    const header = (
        <div className="table-header chamados-table-header">
            <div className="pt-2">
                <h5 className="p-m-0">Todos os chamados</h5>
                <span className="p-input-icon-left d-flex">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => { setSearch(e.target.value); setPage(1);  }} placeholder="Busca..." />
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <Button icon="pi pi-filter" className={'ms-2 text-white'} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />
                </span>
            </div>
            <DateRange
                inputBeginDate={inputBeginDate}
                setInputBeginDate={setInputBeginDate}
                beginDate={beginDate}
                setBeginDate={setBeginDate}
                setInputEndDate={setInputEndDate}
                setEndDate={setEndDate}
                inputEndDate={inputEndDate}
                endDate={endDate}
            />
        </div>
    );

    return (
        <div className="datatable-responsive-demo">

                <div className="card">
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}/>

                    <DataTable ref={dt} value={chamados}
                               dataKey="id" rows={rows}
                               header={header}
                               className={'p-datatable-responsive-demo'}
                               loading={loading}>

                        <Column headerStyle={{width: '3rem'}} />
                        <Column headerStyle={{width: '10rem'}} field="service_number" header="Nº Chamado" body={(rowData) => renderServiceNumber(rowData)} />
                        <Column field="company.name" header="Empresa"  />
                        <Column field="error_description" header="Descrição do problema" />
                        <Column field={'machine_stopped'} header="Máquina parada" body={(rowData) => rowData.machine_stopped ? 'Sim' : 'Não'}/>
                        <Column field="service_date" header="Data" body={(rowData) => dataConstruct(rowData.service_date)}/>
                        <Column field="tech.name" header="Técnico" body={techBodyTemplate} />
                        <Column field="status" header="Status" body={statusBodyTemplate}/>
                        <Column header="Ações" body={actionBodyTemplate} />

                    </DataTable>
                    <div className={'d-flex justify-content-center align-items-center p-3'}>
                        <div>
                            <Pagination className="pagination" color={'primary'} boundaryCount={1} count={ Math.ceil(counter/rows) } onChange={ handleChangePage } />
                        </div>
                        <RowsDropdown rows={rows} setRows={setRows} />
                    </div>

                </div>

                <CreateChamadosForm chamados={chamados} setLoading={setLoading} reload={getChamados} setToast={setToastOptions} header={'Novo chamado'} chamado={singleChamado} setChamado={setSingleChamado} visible={productDialog} onHide={hideDialog} />

                <ConfirmModal type={'reject'}
                              visible={acceptModal}
                              onHide={() => setAcceptModal(false)}
                              refused={() => setAcceptModal(false)}
                              accepted={cancelChamado}
                              label={'Deseja cancelar esse chamado?'}
                              extra={'Se cancelar esse chamado, terá de abrir outro para resolver seu problema.'}
                />

                <Dialog header={"Chamado"} visible={singleChamadoModal} style={{ width: '50vw' }} onHide={() => {setSingleChamadoModal(false);document.body.style.position = null; }} baseZIndex={1000}>

                    <div className="container-fluid">
                        <div className="row flex-row row-reverse">
                            <div className="col-md-6">
                                <strong>Chamado: </strong>
                                <p>{ renderServiceNumber(singleChamado)}</p>

                                <strong>Maquina Parada: </strong>
                                <p>{ singleChamado.machine_stopped === 0 ? 'Não' :  'Sim'}</p>

                                <strong>Nome empresa: </strong>
                                <p>{ singleChamado.company?.name }</p>

                                <strong>Data do chamado: </strong>
                                <p>{ dataConstruct(singleChamado.service_date) } </p>

                                <strong>Status: </strong>
                                <p>{ singleChamado.status }</p>

                                <strong>Qualidade: </strong>
                                <p>{ singleChamado.quality }</p>

                                <strong>Nome de contato: </strong>
                                <p>{ singleChamado.company?.contact_name }</p>

                                <strong>Telefone: </strong>
                                <p>{ masks.phone(singleChamado.company?.phone) }</p>

                                <strong>Aberto Por: </strong>
                                <p>{ singleChamado.created_by?.name }</p>

                                <strong>Aceite técnico: </strong>
                                <p>{ singleChamado?.accepted_by_tech === 1 ? 'Sim' : 'Não' }</p>

                                <strong>É retrabalho: </strong>
                                <p>{ singleChamado?.is_rework === 1 ? 'Sim' : 'Não' }</p>


                            </div>

                            <div className="col-md-6">

                                <strong>Descricao do Problema: </strong>
                                <p>{ singleChamado.error_description }</p>

                                <strong>Hora do aviso tecnico </strong>
                                <p>{ singleChamado.tech_warn_time }</p>

                                <strong>Hora do chamado - Cliente </strong>
                                <p>{ singleChamado.costumer_created_time }</p>

                                <strong>Tecnico: </strong>
                                <p>{ singleChamado.tech?.name }</p>

                                <strong>Relatorio: </strong>
                                <p>{ singleChamado.report_number }</p>

                                <strong>Data do Atendimento </strong>
                                <p>{ dataConstruct(singleChamado.costumer_service_date) }</p>

                                <strong>Hora de Atendimento </strong>
                                <p>{ singleChamado.costumer_service_time }</p>

                                <strong>Observações: </strong>
                                <p>{ singleChamado.comments }</p>

                                <strong>Serviço: </strong>
                                <p>{ singleChamado.service_type }</p>
                                
                                <strong>Equipamento: </strong>
                                <p>{singleChamado.equipment && (singleChamado.equipment?.equipment + ' | ' + singleChamado.equipment?.brand?.name + ' | ' + singleChamado.equipment?.model?.name)}</p>

                                
                                <strong>Justificativa do cancelamento: </strong>
                                <p>{ singleChamado.cancel_justification }</p>

                                {
                                    singleChamado?.is_rework &&
                                    <> 
                                        <strong>Retrabalho: </strong>
                                        <p>{renderServiceNumber(singleChamado.rework) + ' | ' + singleChamado?.rework?.company?.name}</p>
                                    </>
                                    
                                }

                                
                            </div>

                        </div>

                    </div>
                </Dialog>


            </div>
    )

}

export default ChamadosTable;
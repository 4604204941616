import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import Drawer from "../../../components/Drawer";
import CreateChamadosForm from "../../../components/CreateChamadosForm";
import api from "../../../services/api";
import Pagination from "@material-ui/lab/Pagination";
import { useAuth } from "../../../contexts/AuthContext";
import "./styles.css";
import ConfirmModal from "../../../components/ConfirmModal";

const Pendentes = () => {
  const { access_type } = useAuth();

  const [acceptModal, setAcceptModal] = useState(false);
  const [toastOptions, setToastOptions] = useState({});

  const [productDialog, setProductDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  const [singleChamado, setSingleChamado] = useState({});

  const toast = useRef(null);
  const dt = useRef(null);
  const [chamadosAll, setChamadosAll] = useState([]);
  const [counter, setCounter] = useState([]);

  const [page, setPage] = useState(1);

  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    api.get("companies/all").then((response) => {
      setEmpresas(response.data);
    });
  }, [access_type]);

  const [tecnicos, setTecnicos] = useState([]);

  useEffect(() => {
    if (access_type === "Admin") {
      api.get("/users/getTechs").then((response) => {
        setTecnicos(response.data);
      });
    }
  }, [access_type]);

  useEffect(() => {
    if (Object.keys(toastOptions).length !== 0) {
      toast.current.show({
        severity: toastOptions.severity,
        summary: "",
        detail: toastOptions.detail,
        life: 5000
      });
    }
  }, [toastOptions]);

  useEffect(() => {
    getPendingServiceCalls();
  }, [page, access_type]);

  const getPendingServiceCalls = async () => {
    setLoading(true);
    await api.get(`service_calls/pending?page=${page}`).then((response) => {
      setChamadosAll(response.data.data);
      setCounter(response.data.total);
      setLoading(false);
    });
    setLoading(false);
  };

  const openNew = (rowData = null) => {
    setProductDialog(true);
    if (rowData) {
      rowData.aprovar = true;
      setSingleChamado(rowData);
    }
  };

  const hideDialog = () => {
    setSingleChamado(false);
    setProductDialog(false);
  };

  function dataConstruct(data) {
    if (data === undefined || data === "") {
      return "";
    }

    if (data != null) {
      let splitT = data.split("T");
      let split = splitT[0].split("-");
      return split[2] + "/" + split[1] + "/" + split[0];
    }
  }

  const openAcceptModal = (rowData) => {
    setSingleChamado(rowData);
    setAcceptModal(true);
  };

  const actionBodyTemplate = (rowData) => {
    if (access_type === "Admin") {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-check-circle"
            tooltip="Aceitar"
            tooltipOptions={{ position: "top" }}
            className="p-button-rounded p-button-success text-white p-mr-2 me-2"
            onClick={() => openNew(rowData)}
          />
          <Button
            icon="pi pi-times-circle"
            tooltip="Cancelar"
            tooltipOptions={{ position: "top" }}
            className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
            onClick={() => openAcceptModal(rowData)}
          />
        </React.Fragment>
      );
    } else if (access_type === "Costumer") {
      return (
        <React.Fragment>
          <Button
            icon="pi pi-times-circle"
            tooltip="Cancelar"
            tooltipOptions={{ position: "top" }}
            className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
            onClick={() => openAcceptModal(rowData)}
          />
        </React.Fragment>
      );
    }
  };

  const header = (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">Chamados pendentes de aprovação</h5>
      </div>
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const cancelChamado = async () => {
    await api
      .post(`service_calls/cancel/${singleChamado.id}`)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "",
          detail: response.data.response,
          life: 5000
        });
      });

    setSingleChamado({});
    setAcceptModal(false);
    await getPendingServiceCalls();
  };

  const renderServiceNumber = (rowData) => {
    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData.service_number;
  };

  return (
    <div className="datatable-responsive-demo">
      <Toast ref={toast} position="bottom-right" />

      <div className="card">
        <DataTable
          ref={dt}
          value={chamadosAll}
          dataKey="id"
          rows={10}
          header={header}
          className={"p-datatable-responsive-demo"}
          loading={loading}
        >
          <Column headerStyle={{ width: "3rem" }} />
          <Column
            field="service_number"
            header="Nº Chamado"
            body={(rowData) => renderServiceNumber(rowData)}
          />
          <Column field="company.name" header="Empresa" />
          <Column field="error_description" header="Descrição do problema" />
          <Column
            field={"machine_stopped"}
            header="Máquina parada"
            body={(rowData) => (rowData.machine_stopped ? "Sim" : "Não")}
          />
          <Column
            field="service_date"
            header="Data"
            body={(rowData) => dataConstruct(rowData.service_date)}
          />
          <Column field="created_by.name" header="Aberto por" />
          <Column header="Ações" body={actionBodyTemplate} />
        </DataTable>
        <div className={"d-flex justify-content-center align-items-center p-3"}>
          <Pagination
            className="pagination"
            color={"primary"}
            boundaryCount={1}
            count={Math.ceil(counter / 9)}
            onChange={handleChangePage}
          />
        </div>
      </div>

      <ConfirmModal
        type={"reject"}
        visible={acceptModal}
        onHide={() => setAcceptModal(false)}
        refused={() => setAcceptModal(false)}
        accepted={cancelChamado}
        label={"Deseja cancelar esse chamado?"}
        extra={
          "Se cancelar esse chamado, terá de abrir outro para resolver seu problema."
        }
      />

      <CreateChamadosForm
        setToast={setToastOptions}
        setLoading={setLoading}
        reload={getPendingServiceCalls}
        toast={toast}
        header={"Aprovar chamado"}
        setChamado={setSingleChamado}
        chamado={singleChamado}
        visible={productDialog}
        onHide={hideDialog}
        call={singleChamado}
        empresas={empresas}
        tecnicos={tecnicos}
      />
    </div>
  );
};

export default Pendentes;

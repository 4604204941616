import React, {useEffect, useState} from 'react';
import Drawer from "../../components/Drawer";
import { Chart } from 'primereact/chart'
import api from "../../services/api";
import {useAuth} from "../../contexts/AuthContext";
import LoadingChamados from "../../components/LoadingChamados";

const Home = () => {
    const { token, name } = useAuth();

    const [loading, setLoading] = useState(false);

    const [otimos, setOtimos] = useState(0);
    const [ruins, setRuins] = useState(0);
    const [bons, setBons] = useState(0);



    const basicData = {
        labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        datasets: [
            {
                label: 'Abertos',
                backgroundColor: '#42A5F5',
                data: [65, 59, 80, 81, 56, 55, 40, 40, 30, 39, 14, 155]
            },
            {
                label: 'Fechados',
                backgroundColor: '#FFA726',
                data: [28, 48, 40, 19, 86, 27, 90, 65, 59, 80, 81, 56]
            }
        ]
    };

    const [range, setRange] = useState([new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 1)]);
    const [beginDate, setBeginDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const chartData = {
        labels: ['Ótimos', 'Bons', 'Ruins'],
        datasets: [
            {
                data: [otimos, bons, ruins],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]
            }
        ]
    };

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    useEffect(() => {

        getTheQuantityOfTheQuality()

    }, [range]);

    async function getTheQuantityOfTheQuality() {
        setLoading(true);
        await api.get(`/service_calls/countTheQuality?beginDate=${beginDate}&endDate=${endDate}`).then(response => {
            setOtimos(response.data.greats);
            setRuins(response.data.bad);
            setBons(response.data.good);
        }).finally(() => setLoading(false));

    }

    let basicOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor: '#495057'
                }
            }]
        }
    };

    return (
        <>
            <LoadingChamados load={loading}/>
            <div className={'row'}>
                <h5>Bem-vindo(a), {name}</h5>
                <div className={'col-md-4'}>
                    <div className={'card shadow'}>
                        <div className={'card-title'}>
                            <div className="card-body">
                                <h5 className="card-title">Relação de chamados</h5>
                                <p className="card-text d-flex justify-content-center">
                                    <Chart type="pie" width={'300px'} data={chartData} options={lightOptions} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'col-md-4'}>
                    <div className={'card shadow'}>
                        <div className={'card-title'}>
                            <div className="card-body">
                                <h5 className="card-title">Relação de chamados</h5>
                                <p className="card-text d-flex justify-content-center">
                                    <Chart type="pie" width={'300px'} data={chartData} options={lightOptions} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'col-md-4'}>
                    <div className={'card shadow'}>
                        <div className={'card-title'}>
                            <div className="card-body">
                                <h5 className="card-title">Qualidade chamados</h5>
                                <p className="card-text d-flex justify-content-center">
                                    <Chart type="pie" width={'300px'} data={chartData} options={lightOptions} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'col-md-12 col-sm-4 pt-3'}>
                <div className={'card shadow'}>
                    <div className={'card-title'}>
                        <div className="card-body">
                            <h5 className="card-title">Relação de abertos/fechados por mês</h5>

                            <p className="card-text d-flex justify-content-center">
                                <Chart type="bar" width={'800px'} data={basicData} options={basicOptions} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Home;
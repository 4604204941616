import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import React from "react";

const DateRange = ({
  inputBeginDate,
  setInputBeginDate,
  beginDate,
  setBeginDate,
  setInputEndDate,
  setEndDate,
  inputEndDate,
  endDate
}) => {
  const getCurrentDate = (data) => {
    if (data && "toISOString" in data) {
      return data.toISOString().slice(0, 10);
    }
  };

  const handleChangeBeginDate = (e) => {
    if ((!e || !e.value) && !beginDate) {
      const date = new Date(new Date().getFullYear(), 0, 1);
      setInputBeginDate(date);
      setBeginDate(getCurrentDate(date));
      return;
    }

    if (e.type !== "blur") {
      const date = e.value ? e.value : e;
      setInputBeginDate(date);
      const currentDate = getCurrentDate(date);
      if (currentDate && currentDate.length === 10) {
        setBeginDate(currentDate);
      }

      return;
    }

    setBeginDate(getCurrentDate(inputBeginDate));
  };

  const handleChangeEndDate = (e) => {
    if ((!e || !e.value) && !endDate) {
      const date = new Date(new Date().getFullYear(), 11, 31);
      setInputEndDate(date);
      setEndDate(getCurrentDate(date));
      return;
    }

    if (e.type !== "blur") {
      const date = e.value ? e.value : e;
      setInputEndDate(date);
      const currentDate = getCurrentDate(date);
      if (currentDate && currentDate.length === 10) {
        setEndDate(currentDate);
      }

      return;
    }
    setEndDate(getCurrentDate(inputEndDate));
  };

  const handleSetDefaultDates = () => {
    const endDate = new Date(new Date().getFullYear(), 11, 31);
    setInputEndDate(endDate);
    const beginDate = new Date(new Date().getFullYear(), 0, 1);
    setInputBeginDate(beginDate);
    setBeginDate("");
    setEndDate("");
  };

  return (
    <div className="p-field pt-2">
      <h5 className="p-m-0">Período</h5>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className={"chamados-table-header-periodo"}>
          <div>
            <small>Início</small>
            <Calendar
              style={{ width: "100%" }}
              mask="99/99/9999"
              value={inputBeginDate}
              onChange={handleChangeBeginDate}
              onBlur={handleChangeBeginDate}
            />
          </div>
          <div>
            <small>Fim</small>
            <Calendar
              style={{ width: "100%" }}
              mask="99/99/9999"
              value={inputEndDate}
              onChange={handleChangeEndDate}
              onBlur={handleChangeEndDate}
            />
          </div>
        </div>
        <Button
          label={"Ano Vigente"}
          onClick={handleSetDefaultDates}
          className={"text-white ms-2 mt-2"}
          style={{ fontWeight: "regular" }}
        />
      </div>
    </div>
  );
};

export default DateRange;

import React from "react";
import DateRange from "./DateRange";
import Menu from "./HeaderMenu";
const Header = ({ title, context, ...props }) => {
  if (!context) return null;
  const ExtraHeader = context?.extraHeader;
  return (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">{title}</h5>
        <div className="d-flex gap-2">
          {context?.simple ? null : <Menu context={context} />}

          {context?.extraHeader ? <ExtraHeader {...props} /> : null}
        </div>
      </div>
      {context?.setBeginDate && !context?.simple ? (
        <DateRange context={context} />
      ) : null}
    </div>
  );
};

export default Header;

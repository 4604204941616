import { Badge } from "primereact/badge";
import React from "react";

const statusBodyTemplate = (rowData) => {
  const statusBody = [
    {
      status: "Aberto",
      content: (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#9E3131", color: "white" }}
          value={rowData.status}
        />
      )
    },
    {
      status: "Em andamento",
      content: (
        <Badge
          style={{ minWidth: 100, color: "white" }}
          severity={"warning"}
          value={rowData.status}
        />
      )
    },
    {
      status: "Fechado",
      content: (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#003B17", color: "white" }}
          value={rowData.status}
        />
      )
    }
  ];

  const body = statusBody.find((status) => status.status === rowData.status);

  if (body) return body.content;

  return (
    <Badge
      style={{ minWidth: 100, backgroundColor: "gray", color: "white" }}
      value={rowData.status}
    />
  );
};

const renderPatSerie = (rowData) => {
  if (rowData.service_type === "A faturar") {
    return rowData.equipment.serial_number;
  }

  return rowData.equipment.pat;
};

const renderServiceNumber = (rowData) => {
  const date = rowData.workshop_received_date || rowData.created_at;
  const year = date?.split("-")[0];
  return year + "." + rowData.service_number;
};

const dataConstruct = (rowData) => {
  const data = rowData.workshop_received_date;

  if (!data) {
    return "";
  }

  let splitT = data.split("T");
  let split = splitT[0].split("-");
  return split[2] + "/" + split[1] + "/" + split[0];
};

export const tableData = [
  {
    name: "service_number",
    header: "Nº da OS",
    headerStyle: { width: "10rem" },
    body: renderServiceNumber,
    sortable: true
  },
  {
    name: "company.name",
    header: "Empresa",
    sortable: true
  },
  {
    name: "error_description",
    header: "Descrição do problema",
    sortable: true
  },
  {
    name: "equipment.serial_number",
    header: "PAT/SERIE",
    sortable: true,
    body: renderPatSerie
  },
  {
    name: "workshop_received_date",
    header: "Data",
    body: dataConstruct,
    sortable: true
  },
  {
    name: "status",
    header: "Status",
    body: statusBodyTemplate,
    sortable: true
  },
  {
    name: "Ações",
    header: "Ações",
    sortable: false
  }
];

import React from 'react';
import ReactLoading from 'react-loading';

import './styles.css';

const LoadingChamados = ({ load }) => {
    return (
        <>
            { load &&
            <div className="Loading">
                <ReactLoading className={'LoadingComponent'} type={'bars'} color={'#F3C843'} height={100} width={100} />
            </div>
            }
        </>


    );
};

export default LoadingChamados;
import React, { createContext, useContext, useState } from "react";
import api from "../../services/api";
import { tableData } from "./ConstData";

const ServiceOrderContext = createContext({});

export default function ServiceOrderProvider({ children }) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [formModalOpened, setFormModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const getFromApi = ({
    search = "",
    page = 1,
    orderBy = "",
    order = -1,
    rows = 10,
    beginDate = "",
    endDate = "",
    status = "",
    setCounter = () => {}
  } = {}) => {
    setLoading(true);
    api
      .get(
        `service_order?page=${page}&search=${search}&orderBy=${orderBy}&order=${order}&beginDate=${beginDate}&endDate=${endDate}&status=${status}&rows=${rows}`
      )
      .then((response) => {
        setItems(response.data.data);
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const create = async (payload) => {
    setLoading(true);
    return api
      .post("service_order", payload)
      .then((response) => response)
      .catch((error) => error)
      .finally(() => setLoading(false));
  };

  const update = async (payload, id) => {
    setLoading(true);
    return api
      .put(`service_order/${id}`, payload)
      .then((response) => response)
      .catch((error) => error)
      .finally(() => setLoading(false));
  };

  const exportCSV = async ({
    search = "",
    beginDate = "",
    endDate = "",
    status = ""
  } = {}) => {
    setLoading(true);
    api
      .get(
        `/service_order/export-excel?beginDate=${beginDate}&endDate=${endDate}&status=${status}&search=${search}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "ordem-servico-" + new Date().getTime() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const contextData = {
    items,
    setItems,
    selectedItem,
    setSelectedItem,
    loading,
    setLoading,
    tableData,
    getFromApi,
    title: "Ordem de serviço",
    create,
    update,
    formModalOpened,
    setFormModalOpened,
    exportFunction: exportCSV
  };

  return (
    <ServiceOrderContext.Provider value={contextData}>
      {children}
    </ServiceOrderContext.Provider>
  );
}

export function useServiceOrder() {
  return useContext(ServiceOrderContext);
}

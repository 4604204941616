import { InputText } from "primereact/inputtext";
import React from "react";

const HeaderMenu = ({ context }) => {
  return (
    <span className="p-input-icon-left d-flex">
      <i className="pi pi-search" />

      <InputText
        type="search"
        onInput={(e) => {
          context.setSearch(e.target.value);
          context.setPage(1);
        }}
        placeholder="Busca..."
      />
    </span>
  );
};

export default HeaderMenu;

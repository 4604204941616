import React from 'react';
import Grow from "@material-ui/core/Grow";
import {Link} from "react-router-dom";
import {FiArrowLeft} from "react-icons/fi";
import { useHistory } from 'react-router-dom';

const NotFound = () => {

    const history = useHistory();

  return (
      <div className="login-container">
        <div className="container h-100">
          <div className={'row h-100'}>
            <div className={'col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center h-100'}>

              <Grow in={true} timeout={1000}>
                <div className={'d-flex flex-column col-md-6 shadow rounded p-sm-5 justify-content-center align-content-center'} style={{ padding: 80, background: '#fefefe' }} >

                    <div className={'row d-flex flex-column justify-content-center align-content-center'}>
                        <h2>404 - Página não existe</h2>

                        <button onClick={ () => history.push('/')} className="button">
                            Voltar
                        </button>
                    </div>
                </div>
              </Grow>
            </div>
          </div>
        </div>
      </div>

  );
};

export default NotFound;
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";

export default function ServiceCallFilters({
  isVisible = true,
  onHide = () => {},
  setMultiEmpresas,
  setMultiTechs
}) {
  const [selectedTechs, setSelectedTechs] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [tecnicos, setTecnicos] = useState([]);
  const { access_type } = useAuth();

  useEffect(() => {
    api.get("companies/all").then((response) => {
      setEmpresas(response.data);
    });
    if (access_type === "Admin") {
      api.get("/users/getTechs").then((response) => {
        setTecnicos(response.data);
      });
    }
  }, [access_type]);

  const setValues = () => {
    setMultiEmpresas(selectedCompanies);
    setMultiTechs(selectedTechs);
    onHide();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          onHide();
          setSelectedCompanies([]);
          setSelectedTechs([]);
        }}
      />
      <Button
        label={"Aplicar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={setValues}
      />
    </React.Fragment>
  );

  const getOptions = (array) => {
    return array.map((item) => {
      return {
        label: item.name,
        value: item.id
      };
    });
  };

  return (
    <Dialog
      header={"Filtros"}
      visible={isVisible}
      style={{ width: "50vw" }}
      footer={footer}
      onHide={() => {
        onHide();
        setSelectedCompanies([]);
        setSelectedTechs([]);
        document.body.style.position = null;
      }}
    >
      <p style={{ fontWeight: "bold", fontSize: "18px" }}>
        Selecione as empresas
      </p>
      <MultiSelect
        value={selectedCompanies}
        style={{ width: "100%" }}
        options={getOptions(empresas)}
        display="chip"
        filter
        onChange={(e) => setSelectedCompanies(e.value)}
      />

      <p style={{ fontWeight: "bold", fontSize: "18px", marginTop: 24 }}>
        Selecione os tecnicos
      </p>
      <MultiSelect
        value={selectedTechs}
        style={{ width: "100%" }}
        options={getOptions(tecnicos)}
        display="chip"
        filter
        onChange={(e) => setSelectedTechs(e.value)}
      />
    </Dialog>
  );
}

import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import RowsDropdown from "../../components/RowsDropdown";
import ReportFilters from "../../components/ReportFilters/ReportFilters";
import DateRange from "../../components/ToolbarComponents/DateRange";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Table = () => {
  const { access_type } = useAuth();
  const history = useHistory();
  const [toastOptions, setToastOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [rows, setRows] = useState(10);

  const toast = useRef(null);
  const dt = useRef(null);
  const [corretivas, setCorretivas] = useState([]);
  const [counter, setCounter] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);

  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState("date");
  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setSortField(sortField);
  };
  const [showFilter, setShowFilter] = useState(false);
  const [multiEmpresas, setMultiEmpresas] = useState([]);
  const [multiTechs, setMultiTechs] = useState([]);
  const onHideFilter = () => setShowFilter(false);

  useEffect(() => {
    getCorretivas();
  }, [
    page,
    search,
    beginDate,
    endDate,
    sortOrder,
    access_type,
    rows,
    sortField,
    multiEmpresas,
    multiTechs
  ]);

  const getCorretivas = async () => {
    setLoading(true);
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    await api
      .get(
        `/corrective?page=${page}&search=${search}&order=${sortOrder}&orderBy=${sortField}&beginDate=${beginDate}&endDate=${endDate}&rows=${rows}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}`
      )
      .then((response) => {
        setCorretivas(response.data.data);
        setCounter(response.data.total);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(toastOptions).length !== 0) {
      toast.current.show({
        severity: toastOptions.severity,
        summary: "",
        detail: toastOptions.detail,
        life: 5000
      });
    }
  }, [toastOptions]);

  const exportExcel = async () => {
    toast.current.show({
      severity: "info",
      summary: "",
      detail: "Gerando excel, aguarde...",
      life: 5000
    });
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    setLoading(true);

    api
      .get(
        `/corrective/export/excel?beginDate=${beginDate}&endDate=${endDate}&search=${search}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const startDate =
          beginDate ||
          new Date(new Date().getFullYear(), 0, 1).toLocaleDateString();
        const finalDate =
          endDate ||
          new Date(new Date().getFullYear(), 11, 31).toLocaleDateString();
        link.setAttribute(
          "download",
          `corretivas-${startDate}-${finalDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail:
            "Ocorreu um erro ao gerar excel, contate um administrador: " +
            error.message,
          life: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportExcel}
        />
      </React.Fragment>
    );
  };

  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const downloadReport = async (rowData) => {
    setLoading(true);

    api
      .get("/report/corrective/pdf/" + rowData.id, {
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const reportNumber = rowData?.report.padStart(6, 0);
        link.setAttribute("download", reportNumber + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {
          <Button
            icon="pi pi-file-pdf"
            style={{
              backgroundColor: "#7f0b0b",
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button text-white"
            onClick={() =>
              window.open(`/pdf/corrective;${rowData.id}`, "_blank").focus()
            }
          />
        }
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">Corretivas</h5>
        <span className="p-input-icon-left d-flex">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
          {access_type === "Admin" && (
            <Button
              style={{ color: "white", marginLeft: 8 }}
              icon="pi pi-filter"
              onClick={() => setShowFilter(true)}
            />
          )}
        </span>
      </div>
      <DateRange
        inputBeginDate={inputBeginDate}
        setInputBeginDate={setInputBeginDate}
        beginDate={beginDate}
        setBeginDate={setBeginDate}
        setInputEndDate={setInputEndDate}
        setEndDate={setEndDate}
        inputEndDate={inputEndDate}
        endDate={endDate}
      />
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const renderServiceNumber = (rowData) => {
    if (!rowData?.service_number) return "Nenhum encontrado.";

    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData.service_number;
  };

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast style={{ zIndex: 1101 }} ref={toast} />
        <div className="card">
          <Toolbar className="p-mb-4" right={rightToolbarTemplate} />

          <DataTable
            ref={dt}
            value={corretivas}
            dataKey="id"
            rows={rows}
            header={header}
            sortOrder={sortOrder}
            sortField={sortField}
            onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
            className={"p-datatable-responsive-demo"}
            loading={loading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column
              headerStyle={{ width: "10rem" }}
              field="report"
              sortable={true}
              header="Relatório"
              body={(rowData) => rowData.report.padStart(6, 0)}
            />
            <Column
              headerStyle={{ width: "10rem" }}
              field="report"
              header="Chamado"
              body={(rowData) => renderServiceNumber(rowData.service_call)}
            />
            <Column field="company.name" header="Empresa" />
            <Column
              field="service_call.equipment.equipment"
              header="Equipamento"
            />
            <Column
              field="date"
              header="Data"
              sortable={true}
              body={(rowData) => dataConstruct(rowData.date)}
            />
            <Column field="created_by.name" header="Criado por" />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(counter / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} />
          </div>
        </div>
      </div>

      <ReportFilters
        setMultiEmpresas={setMultiEmpresas}
        setMultiTechs={setMultiTechs}
        isVisible={showFilter}
        onHide={onHideFilter}
      />
    </>
  );
};

export default Table;

import React from 'react';
import './styles.css';
import ReactLoading from 'react-loading';

const Loading = () => {
    return(
        <div className={'loading-container'} >
            <ReactLoading type={'bars'} color={'white'} height={100} width={100} />
        </div>
    );
};

export default Loading;
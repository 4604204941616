import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import api from "../services/api";

import useLocalStorage from "../hooks/useLocalStorage";
import FirstAccessForm from "../components/FirstAccessForm";
import AcceptTermsModal from "../components/AcceptTermsModal";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const toast = useRef(null);
  const [token, setToken] = useLocalStorage("token", "");
  const [user_id, setUserId] = useLocalStorage("user_id", "");
  const [name, setName] = useLocalStorage("name", "");
  const [is_first_access, setFirstAccess] = useLocalStorage(
    "is_first_access",
    false
  );
  const [acceptedTerms, setAcceptedTerms] = useLocalStorage(
    "acceptedTerms",
    true
  );
  const [access_type, setAccess_type] = useLocalStorage("access_type", "");
  const [owner_company, SetOwner_company] = useLocalStorage(
    "owner_company",
    ""
  );
  const [signed, setSigned] = useLocalStorage("signed", false);
  const [cargo, setCargo] = useLocalStorage("cargo", "");
  const [setor, setSetor] = useLocalStorage("setor", "");
  const [phone, setPhone] = useLocalStorage("telefone", "");
  const [email, setEmail] = useLocalStorage("email", "");
  const [userCompanies, setUserCompanies] = useLocalStorage(
    "user_companies",
    ""
  );
  const [logo, setLogo] = useLocalStorage("logo", "");
  const [userLocation, setUserLocation] = useLocalStorage("userLocation", null);

  useEffect(() => {
    if (!token) {
      setToken(localStorage.getItem("token"));
    }
  });

  const getUserLocation = () => {
    return typeof userLocation === "string"
      ? JSON.parse(userLocation)
      : userLocation;
  };

  const verifyAuth = () => {
    try {
      api.get("/auth/me").then((r) => {
        setUserId(r.data[0].id);
        setName(r.data[0].name);
        setAccess_type(r.data[0].user_type);
        setCargo(r.data[0].office);
        setSetor(r.data[0].sector);
        setPhone(r.data[0].phone);
        setEmail(r.data[0].email);
        SetOwner_company(JSON.stringify(r.data[0].owner_company));
        setUserCompanies(JSON.stringify(r.data[1]));
        setFirstAccess(r.data[0].is_first_access);
        setAcceptedTerms(r.data[0]?.settings?.accepted_privacy_terms);
        const owner_company_name = r.data[0].owner_company.name
          .split(" ")
          .join("-");
        setLogo("images/" + owner_company_name + "/logo.png");

        setSigned(true);
      });

      return true;
    } catch (e) {
      Logout();
      return false;
    }
  };

  useEffect(() => {
    const tokenLocal = localStorage.getItem("token");
    if (tokenLocal && tokenLocal !== "null") {
      verifyAuth();
    } else if (signed && !tokenLocal) {
      setSigned(false);
      Logout();
    }
  }, []);

  const Login = async ({ email, password }) => {
    localStorage.clear();

    try {
      const response = await api.post("/login", {
        email,
        password
      });

      setUserId(response.data.id);
      setName(response.data.name);
      setAccess_type(response.data.access_type);
      setToken(response.data.access_token);
      SetOwner_company(JSON.stringify(response.data.owner_company));
      setFirstAccess(response.data.is_first_access);
      setAcceptedTerms(response.data?.settings?.accepted_privacy_terms);
      if (response.data.owner_company && response.data.owner_company.name) {
        const owner_company_name = response.data.owner_company.name
          .split(" ")
          .join("-");
        setLogo("images/" + owner_company_name + "/logo.png");
      }

      setSigned(true);
    } catch (error) {
      console.log(error);
      setSigned(false);
    }
  };

  const Logout = async () => {
    await api.post("/logout", {}).finally(() => {
      setUserId(null);
      setName(null);
      setAccess_type(null);
      SetOwner_company(null);
      setToken(null);
      setAcceptedTerms(true);
      api.defaults.headers.Authorization = null;
      localStorage.clear();
      setSigned(false);
      setLogo(null);
    });
  };

  const onHide = () => {
    toast.current.show({
      severity: "warn",
      summary: "",
      detail:
        "Para prosseguir você precisa preencher as informações e clicar em salvar.",
      life: 5000
    });
  };

  const onHideAccept = () => {
    toast.current.show({
      severity: "warn",
      summary: "",
      detail: "Aceite os termos de privacidade para prosseguir.",
      life: 5000
    });
  };

  return (
    <AuthContext.Provider
      value={{
        signed: signed,
        Login,
        Logout,
        token,
        user_id,
        name,
        setName,
        access_type,
        owner_company,
        cargo,
        setCargo,
        setor,
        setSetor,
        phone,
        setPhone,
        email,
        userCompanies,
        logo,
        setLogo,
        verifyAuth,
        setFirstAccess,
        setAcceptedTerms,
        acceptedTerms,
        userLocation,
        setUserLocation,
        getUserLocation
      }}
    >
      <FirstAccessForm
        header={"Preencha essas informações para continuar"}
        visible={is_first_access}
        onHide={onHide}
      />
      <AcceptTermsModal
        header={"Aceite os termos para continuar"}
        visible={!acceptedTerms}
        onHide={onHideAccept}
      />

      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

import React, { createContext, useContext, useState } from "react";
import api from "../../services/api";
import { tableData } from "./ConstData";

const LogsContext = createContext({});

export default function LogsProvider({ children }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const getFromApi = ({ page = 1, setCounter = () => {}, id } = {}) => {
    setLoading(true);
    api
      .get(`/logs/getById/${id}?page=${page}`)
      .then((response) => {
        setItems(response.data.data);
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const contextData = {
    items,
    setItems,
    loading,
    setLoading,
    tableData,
    getFromApi,
    title: "Logs do usuário",
    simple: true,
    selectedItem,
    setSelectedItem
  };

  return (
    <LogsContext.Provider value={contextData}>{children}</LogsContext.Provider>
  );
}

export function useLogs() {
  return useContext(LogsContext);
}

import { Dropdown } from "primereact/dropdown";
import React from "react";

const RowsDropdown = ({ setRows, rows }) => {
  const rowsNumber = [
    { label: "10", value: 10 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 }
  ];

  return (
    <div>
      <Dropdown
        optionLabel="label"
        value={rows}
        options={rowsNumber}
        onChange={(e) => setRows(e.value)}
        placeholder={""}
      />
    </div>
  );
};

export default RowsDropdown;

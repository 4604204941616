import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_LARAVEL_APP + 'api',
    //baseURL: 'http://192.168.10.250:3333',
    // baseURL: 'http://testeaxios-com-br.umbler.net/'
});

api.interceptors.request.use((request) => {

    const token = localStorage.getItem('token');
    request.headers = {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Authorization",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        "Content-Type": "application/json;charset=UTF-8"
    }

    return request;

});

api.interceptors.response.use( (response) => {

   if( response.data && response.data.status === 'Token is Expired') {
        logout401();
   }

    return response;

}, (error) => {

    if (error.request && (error.request.status === 401)) {
        logout401();
    }

    return Promise.reject(error);

});

const logout401 = () => {
    localStorage.clear();
    window.location.href = '/login?reason=401';
}

const logout290 = () => {
    localStorage.clear();
    window.location.href = '/login?reason=290';
}

export default api;
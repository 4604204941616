import React, {useEffect, useState} from 'react';
import './styles.css';

import { Fade } from "@material-ui/core";
import NumberFormat from 'react-number-format';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm, Controller } from 'react-hook-form';
import Input from "../Form/Input";
import {useParts} from "../../contexts/PartsContext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import api from "../../services/api";

const schema = z.object({
    email: z.string().email({ message: "Digite um e-mail válido" }).nonempty({ message: 'Digite o código' }),
});


export default function CreateCompanyEmailsForm({ selectedItem, visible = true, onHide = () => {}, reload = () => {}, toast, setLoading}) {

    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({
        resolver: zodResolver(schema),
        mode: 'onBlur',
        reValidateMode: 'onChange',
    });

    const [emails, setEmails] = useState([]);

    useEffect(() => {
        setEmails(selectedItem?.emails?.length > 0 ? selectedItem?.emails : []);
    }, [selectedItem]);

    const submitForm = async (data) => {

        let email = emails.find(email => data.email === email.email);
        if(!email) {
            setEmails([...emails, { email: data.email } ]);
        }

        reset();
    }

    const hideForm = () => {
        reset();
        onHide();
    }


    const removeEmail = (rowData) => {
        setEmails(emails.filter(email =>
            rowData.email !== email.email
        ));
    }

    const createEmails = async () => {
        setLoading(true);
        hideForm();
        await api.post('/companies/emails', { emails, company_id: selectedItem.id });
        toast.current.show({ severity: 'success', summary: '', detail: `E-mails atualizados com sucesso!`, life: 3000 });
        reload();
    }

    const actionBody = (rowData) => {
        return <span onClick={() => removeEmail(rowData)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>X</span>
    }

    const createChamadoAdmin = () => {
        return (

            <Fade in={true}>

                <div className="container">

                    <div className={'row'}>
                        <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
                            <form
                                className={'d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center'}>
                                <div className={'row pb-1'}>
                                    <div className={'col-md-12 pb-3 d-flex flex-column'}>
                                        <Input name={'email'} label={'E-mail'} register={register} error={errors.email?.message} />
                                    </div>
                                </div>
                                <Button label={'Inserir email'} onClick={handleSubmit((data) => submitForm(data))} />
                            </form>
                        </div>
                    </div>
                    <div>
                        <div className="card">
                            <DataTable value={emails} responsiveLayout="scroll">
                                <Column field="email" header="E-mail" />
                                <Column header={'Excluir'} body={(rowData) => actionBody(rowData)} />
                            </DataTable>
                        </div>
                    </div>
                </div>

            </Fade>
        );
    }

    const footer = (
        <React.Fragment>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={ () => { hideForm(); }} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={createEmails} />
        </React.Fragment>
    );


    return (
        <>
            <Dialog visible={visible} style={{ marginTop: 60, width: '80rem' }} header={'E-mails'} modal className="p-fluid" footer={footer} onHide={hideForm} >

                <div className={'container pt-2'}>

                    {createChamadoAdmin()}

                </div>

            </Dialog>
        </>

    );
}

export const phone = (phone) => {

    if(!phone || phone.length > 15) {
        return;
    }

    let v= phone.replace(/\D/g,"");
    v=v.replace(/^(\d{2})(\d)/g,"($1) $2");
    v=v.replace(/(\d)(\d{4})$/,"$1-$2");
    return v;
}

export const renderServiceNumber = (rowData) => {
  const date = rowData.workshop_received_date || rowData.created_at;
  const year = date?.split("-")[0];
  return year + "." + rowData.service_number;
};

const masks = {
  phone,
  renderServiceNumber
};

export default masks;
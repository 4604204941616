
import React from "react";

const Input = ({
                   error, name, label, register, disabled = false, type = 'text', props
               }) => {
    return (
      <>
          <label htmlFor={name}>{label}</label>
          <input id={name}
                 className={error ? 'mb-2 border-danger' : 'mb-2'}
                 type={type}
                 disabled={disabled}

                 {...register(name)}
                 {...props}
          />
          {
              error ?
                  <span className={'text-danger'}>{error}</span>
                  :
                  null
          }
      </>
    );
};
export default Input;
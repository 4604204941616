import React, {useRef, useState} from 'react';
import {Fade} from "@material-ui/core";
import {Toast} from "primereact/toast";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputMask} from "primereact/inputmask";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import api from "../../services/api";
import {useAuth} from "../../contexts/AuthContext";
import LoadingChamados from "../LoadingChamados";



const AcceptTermsModal = ({ visible, header, onHide }) => {
    const toast = useRef();
    const [loading, setLoading] = useState(false);

    const { setAcceptedTerms } = useAuth();
    const [acceptedTerms, setAcceptedTermsModal] = useState(false);
    const [acceptedEmail, setAcceptedEmail] = useState(false); 


    const handleSubmitForm = () => {
        if(!acceptedTerms) {
            toast.current.show({severity: 'warn', summary: 'Termos de privacidade', detail: 'Para utilizar o sistema deve-se aceitar os termos de privacidade!'});
        }
        
        api.post('/auth/settings', {
            accepted_privacy_terms: acceptedTerms,
            send_emails_notifications: acceptedEmail
        }).then(() => {

            setAcceptedTerms(true);

        }).catch(() => {

            toast.current.show({severity: 'err', summary: '', detail: 'Ocorreu um erro ao atualizar.'});

        });

    }

    const footer = (
        <React.Fragment>
            <Button label={"Salvar"} icon="pi pi-check" className="p-button-text" onClick={handleSubmitForm } />
        </React.Fragment>
    );

    const constructCreateChamado = () => {
        return (
            <Fade in={true}>

                <div className="container">

                    <Toast ref={toast} className="p-button-success" />
                    Os nossos termos de privacidade estão presentes no site <a href="https://grupomov.com.br/privacy.html" target="_blank">https://grupomov.com.br/privacy.html</a>, para prosseguir aceite eles abaixo:

                    <div className={'row pt-4'}>

                        <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
                            <form className={'d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center'} onSubmit={ () => {} }>
                                <div className={'row pb-1'}>

                                    <div className={'col-md-12 pb-3'}>
                                        <label htmlFor="Nº Série / PAT">Aceito os termos de privacidade: </label>
                                        <div className="d-flex flex-row justify-content-around pt-2">
                                            <div className="form-check" onClick={() => setAcceptedTermsModal(true)}>
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={acceptedTerms} />
                                                <label className="form-check-label ps-2 pt-1" htmlFor="flexRadioDefault1">
                                                    Aceito
                                                </label>
                                            </div>

                                            <div className="form-check d-flex flex-row" onClick={() => setAcceptedTermsModal(false)}>
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={!acceptedTerms} />
                                                <label className="form-check-label ps-2 pt-1" htmlFor="flexRadioDefault2">
                                                    Não aceito
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={'col-md-12 pb-3'}>
                                        <label htmlFor="Nº Série / PAT">Aceito receber e-mails de informações sobre meus chamados: </label>
                                        <div className="d-flex flex-row justify-content-around pt-2">
                                            <div className="form-check" onClick={() => setAcceptedEmail(true)}>
                                                <input className="form-check-input" type="radio" name="flexRadioDefault3" id="flexRadioDefault3" checked={acceptedEmail} />
                                                <label className="form-check-label ps-2 pt-1" htmlFor="flexRadioDefault3">
                                                    Aceito
                                                </label>
                                            </div>

                                            <div className="form-check" onClick={() => setAcceptedEmail(false)}>
                                                <input className="form-check-input" type="radio" name="flexRadioDefault4" id="flexRadioDefault4" checked={!acceptedEmail} />
                                                <label className="form-check-label ps-2 pt-1" htmlFor="flexRadioDefault4">
                                                    Não aceito
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </form>
                        </div>

                    </div>
                </div>
            </Fade>
        );
    }
    return (
        <>
            <LoadingChamados load={loading}/>
            <Dialog visible={visible} style={{ marginTop: 60 }} header={header} modal className="p-fluid" footer={footer} onHide={onHide} >

                <div className={'container pt-2'}>
                    {constructCreateChamado()}

                </div>

            </Dialog>
        </>

    );

}

export default AcceptTermsModal;
import React, { useState } from "react";

const DateRange = ({ context }) => {
  if (!context) return null;

  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  return (
    <DateRange
      inputBeginDate={inputBeginDate}
      setInputBeginDate={setInputBeginDate}
      beginDate={context.beginDate}
      setBeginDate={context.setBeginDate}
      setInputEndDate={setInputEndDate}
      setEndDate={context.setEndDate}
      inputEndDate={inputEndDate}
      endDate={context.endDate}
    />
  );
};

export default DateRange;

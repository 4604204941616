import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

import PrivateRoutes from './PrivateRoutes';
import CommumRoutes from './CommumRoutes';

const routes = () => {
    const { signed, setUserLocation, access_type, userLocation } = useAuth();

    useEffect(() => {
        
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            setUserLocation(JSON.stringify({ lat: coords.latitude, lng: coords.longitude }));
        });
       
      }, []);
    return signed ? <PrivateRoutes /> : <CommumRoutes />;
};

export default routes;

export const tableData = [
  {
    name: "code",
    header: "Código",
    headerStyle: { width: "10rem" },
    sortable: true
  },
  {
    name: "description",
    header: "Descrição",
    sortable: true
  },
  {
    name: "price",
    header: "Preço",
    sortable: true
  },
  {
    header: "Ações",
    body: null
  }
];

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./multiple-corretives-modal.css";
import api from "../../services/api";

export default function MultipleCorrectivesModal({
  isVisible = false,
  onHide = () => {},
  setLoading = () => {},
  loading = false,
  chamado = null
}) {
  if (!chamado || Object.keys(chamado).length === 0) return null;

  const downloadReport = async (corrective) => {
    return window.open(`/pdf/corrective;${corrective.id}`, "_blank").focus();
  };

  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const renderServiceNumber = (rowData) => {
    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData.service_number;
  };

  return (
    <Dialog
      header={`Corretivas`}
      visible={isVisible}
      style={{ width: "50vw" }}
      onHide={() => {
        onHide();
        document.body.style.position = null;
      }}
    >
      <div className="d-flex flex-column gap-3 justify-content-between">
        <span
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1rem"
          }}
        >{`Chamado: ${renderServiceNumber(chamado)}`}</span>
        <span>
          <strong>Empresa: </strong> {chamado.company.name}
        </span>
        <span>
          <strong>Data do chamado: </strong>{" "}
          {dataConstruct(chamado.service_date)}
        </span>
        <span>
          <strong>Equipamento: </strong>{" "}
          {chamado?.equipment?.equipment +
            " - " +
            chamado?.equipment?.brand?.name +
            " - " +
            chamado?.equipment?.model?.name}
        </span>
        <span>
          <strong>Status: </strong> {chamado.status}
        </span>
      </div>
      {/* <SectionSpliter text={"Corretivas"} /> */}
      <hr />
      <p
        style={{
          fontWeight: "bold",
          textAlign: "center",
          fontSize: "1rem"
        }}
      >
        Corretivas
      </p>
      <div className="d-flex flex-column gap-2">
        {chamado.corrective.map((corrective) => (
          <div className="d-flex flex-row justify-content-between align-items-center corrective-wrapper">
            <span>
              <strong>Data: </strong> {dataConstruct(corrective.created_at)}
            </span>
            <span>
              <strong>Relatório: </strong> {corrective.report.padStart(6, "0")}
            </span>
            <div className="d-flex flex-row justify-content-between gap-2 align-items-center">
              <Button
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 14,
                  outline: "none",
                  border: "none",
                  backgroundColor: "#7f0b0b"
                }}
                icon="pi pi-file-pdf"
                label="Download"
                disabled={loading}
                onClick={() => downloadReport(corrective)}
              />
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  );
}

import React from 'react';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";

import rejectImage from '../../assets/rejectImage.png';
import verifyImage from '../../assets/verifyImage.png';

const ConfirmModal = ( { type, visible, onHide, accepted, refused, label, extra } ) => {

    const activateDialogFooter = (
        <div className={'d-flex justify-content-around align-items-center'}>
            <Button label="Não" className="p-button-text text-white bg-danger w-50" style={{ height: 40, fontWeight: 'bold'}} onClick={refused} />
            <Button label="Sim" className="p-button-text text-white bg-success w-50" style={{ height: 40, fontWeight: 'bold'}} onClick={accepted} />
        </div>
    );

    const getImage = () => {
        if(type === 'verify') {
            return verifyImage;
        } else if(type === 'reject') {
            return rejectImage
        }
    }

    return (
        <Dialog visible={visible} style={{ width: '450px' }} modal footer={activateDialogFooter} onHide={onHide}>
            <div className={'d-flex flex-column align-items-center'}>
                <img width={'150'} className={'pb-3'} src={getImage()} alt={'reject image'}/>
                <h4 className={'text-dark'}>{label}</h4>

                <p style={{ fontSize: 15 }}>{extra}</p>
            </div>
        </Dialog>
    );




}

export default ConfirmModal;
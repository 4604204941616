const QuantityCard = ({
  label,
  color,
  _onClick = null,
  value = 30,
  isSelected
}) => {
  return (
    <div
      onClick={_onClick}
      className="border w-100"
      style={{
        height: 80,
        borderRadius: 8,
        cursor: _onClick ? "pointer" : "",
        opacity: isSelected ? 0.3 : 1
      }}
    >
      <div className="d-flex flex-row p-3 justify-content-center text-center align-items-center mt-2">
        <div>
          <i style={{ color, paddingRight: 20 }} className="pi pi-circle-on" />
        </div>
        <div
          style={{
            fontWeight: "700",
            fontSize: 20,
            paddingRight: 15,
            color: "#666666"
          }}
        >
          {label}
        </div>
        <div style={{ color, fontWeight: "700", fontSize: 20 }}>{value}</div>
      </div>
    </div>
  );
};

export default QuantityCard;

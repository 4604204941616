import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

import './styles.css';
import { useHistory } from 'react-router-dom';

import Loading from "../../components/Loading";

import grupomov from '../../assets/Grupo Mov.png';

import { Toast } from 'primereact/toast';
import {useAuth} from "../../contexts/AuthContext";
import {Button} from "primereact/button";
import api from "../../services/api";

export default function ResetPassword() {

    let [email, setEmail] = useState('');
    let [token, setToken] = useState('');
    let [password, setPassword] = useState('');
    const history = useHistory();

    const [load, setLoad] = useState(false);
    function useQuery() {
        return new URLSearchParams(useLocation().search);

    }

    let query = useQuery();
    const reason = (query.get("reason"));
    
    useEffect(() => {
       setToken(query.get('token'));
       setEmail(query.get('email'));
    }, [])
    
    const toast = useRef(null);

    async function handleLogin(e) {

        e.preventDefault();

        if(email.length === 0 || password.length === 0 || token.length === 0) {
            toast.current.show({severity:'warn', summary: 'Aviso', detail:'Preencha todos os campos', life: 5000});
            return;
        }

        setEmail(email.trim());

        setLoad(true);

        setTimeout(async () => {
            try {
                await api.post('/resetPasswordByToken', {
                    email,
                    password, 
                    token
                });

                setLoad(false)

                
                toast.current.show({severity:'success', summary: '', detail:`Sua senha foi alterada corretamente. Redirecionando para a pagina em 5 segundos...`, life: 5000});

                
                setInterval(() => {

                    history.push("/login");

                }, 5000);

            } catch(err) {
                setLoad(false)
                toast.current.show({severity:'error', summary: 'Erro', detail:'Confira os dados enviados e tente novamente.', life: 5000});
            }
        }, 2000);


    }

    return (
        <>
            { load ? <Loading /> : null }
                <div className="login-container">
                    <Toast ref={toast} position="bottom-right" />
                    <div className="container h-100">
                    <div className={'row h-100'}>
                        <div className={'col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center h-100'}>
                            <h1 style={{ display: 'none' }}>Chamados grupomov</h1>
                            <Grow in={true} timeout={1000}>
                            <form className={'d-flex flex-column col-md-6 shadow rounded p-sm-5 justify-content-center align-content-center'}
                                    onSubmit={handleLogin}
                                    style={{ padding: 80, background: '#fefefe' }}
                                >
                                    <img src={grupomov} alt="Grupo mov" style={{ width: '12em' }} className="img-fluid mb-5 mx-auto"/>

                                    <div className="d-flex flex-column mt-2">
                                        { reason === '401' && <span className={'p-2 text-danger justify-content-center align-items-center d-flex'}>Erro na autenticação</span> }

                                        <span className="p-float-label">

                                            <InputText id="email" type="email" name={'email'} value={email}
                                            onChange={e => setEmail(e.target.value)}/>

                                            <label htmlFor="email">E-mail</label>
                                        </span>


                                        <span className="p-float-label mt-4 mb-2">

                                            <InputText id="token" type="text" name={'token'} value={token}
                                                onChange={e => setToken(e.target.value)}/>

                                            <label htmlFor="token">Token</label>

                                        </span>
                                        
                                        
                                        <Password id="" toggleMask={true} name={'password'} feedback={false} type="password" className={'mt-2 p'} placeholder="Nova Senha" value={password}
                                        onChange={e => setPassword(e.target.value)} />

                                       
                                    </div>
                                    
                                    <div className="btnLoginPage mt-3 d-flex flex-column align-items-center">
                                        <Button label={'Resete sua senha'} style={{
                                            backgroundColor: '#ffb922',
                                            outline: 0, border: 'none',
                                            width: '100%',
                                            fontWeight: 'bold',
                                            height: 46,
                                            borderRadius: 16
                                        }} className="p-button-success" type={'submit'} />


                                    </div>

                                    <div className="d-flex flex-row align-items-center justify-content-center mt-3 ">
                                        <p className="loginA" onClick={() => history.push("/login")}>Voltar ao login</p>
                                    </div>
                                </form>
                            </Grow>
                        </div>

                    </div>
                </div>
            </div>
        </>

    );
}
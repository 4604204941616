import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Login from '../pages/Login';
import Cadastro from '../pages/Cadastro';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {ptBR} from "@material-ui/core/locale";


const theme = createMuiTheme({
    palette: {
        primary: { main: '#0A073B' },
    },
}, ptBR);


export default function CommumRoutes() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/cadastro" component={Cadastro}/>
                    <Route path="/resetpassword" component={ResetPassword}/>
                    <Route path="/forgetpassword" component={ForgotPassword}/>
                    <Redirect to="/login" />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}
import React, {useEffect, useState, useRef} from 'react';
import Drawer from "../../components/Drawer";
import {Divider} from "primereact/divider";
import {InputMask} from "primereact/inputmask";
import {InputText} from "primereact/inputtext";
import {useAuth} from "../../contexts/AuthContext";
import Loading from "../../components/LoadingChamados";
import {Grow, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Button} from "primereact/button";
import {Password} from "primereact/password";
import api from "../../services/api";
import {Toast} from "primereact/toast";
import { FileUpload } from 'primereact/fileupload';
import {Tag} from "primereact/tag";
import {ProgressBar} from "primereact/progressbar";
const Profile = () => {
    const toast = useRef(null);
    const { name, email, phone, cargo, setor, userCompanies, access_type, setLogo, owner_company, verifyAuth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState('inicio');




    useEffect(() => {

        setLoading(true);

        setDefaultValues();

        setTimeout(() => {
            setLoading(false);
        }, 2000);

    }, [name, email, phone, cargo, setor]);

    const [nome, setName]   = useState(name);
    const [telefone, setPhone] = useState(phone);
    const [email2, setEmail] = useState(email);
    const [cargo2, setCargo] = useState(cargo);
    const [setor2, setSetor] = useState(setor);
    const [empresas, setEmpresas] = useState(JSON.parse(userCompanies ? userCompanies : '[]'));
    const [owner_companyObject, setOwnerCompanyObject] = useState(JSON.parse(owner_company ? owner_company : '[]'))
    const [senha, setPassword] = useState('');
    const [novaSenha, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    useEffect(() => {
        verifyAuth();
        setName(name);
        setEmail(email);
        setPhone(phone);
        setCargo(cargo);
        setSetor(setor);

    }, [name, email, phone, cargo, setor])

    const setActiveSection = (section) => {
        setActive(section);
    }

    const handleEditProfile = async (e) => {
        e.preventDefault();
        setLoading(true);
        await setTimeout(async () => {

            const data = {
                name: nome,
                phone: telefone,
                email: email2,
                office: cargo2,
                sector: setor2
            }
            try {
                await api.put('/auth/update', data);
                toast.current.show({ severity: 'success', summary: '', detail: `Alterado com sucesso`, life: 3000 });
                verifyAuth();
            } catch (e) {
                toast.current.show({ severity: 'error', summary: '', detail: `Erro ao alterar`, life: 3000 });

            }

           setLoading(false);
        }, 2000);
    }

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            password: senha,
            newPassword: novaSenha,
            passwordConfirm: newPasswordConfirm
        }

        await setTimeout(async () => {

            try {
                await api.post('/auth/changePassword', data);
                toast.current.show({ severity: 'success', summary: '', detail: `Alterado com sucesso`, life: 3000 });
            } catch (e) {
                if(e.request.status === 400 || e.request.status === 400) {
                    const errors = JSON.parse(e.request.response);
                    for (let i in errors) {
                        errors[i].map(error => {
                            toast.current.show({ severity: 'error', summary: '', detail: error, life: 7000 });
                        })
                    }

                } else {
                    toast.current.show({ severity: 'error', summary: '', detail: 'Ocorreu um erro ao alterar', life: 7000 });
                }

            }

            setLoading(false);
        }, 2000);
    }

    const setDefaultValues = () => {
        setName(name);
        setPhone(phone);
        setEmail(email);
        setCargo(cargo);
        setSetor(setor);
        setEmpresas(JSON.parse(userCompanies ? userCompanies : '[]'));
        setNewPassword('');
        setNewPasswordConfirm('');
        setPassword('');
    }

    useEffect(() => {

        setDefaultValues();
        renderSection();

    }, [active]);

    const renderSection = () => {
        if(active === 'inicio') {
            return personalDataSection();
        } else if(active === 'empresas') {
            return myCompaniesSection();
        } else if (active === 'password'){
            return passwordSection();
        } else if(active === 'changeImage') {
            return changeImageSection();
        }
    }
    const cnpjMask = value => {
        return value
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    }


    const [image, setImage] = useState('');

    const handleChangeImage = (event) => {

        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    }

    const handleUploadImage = async (e) => {
        e.preventDefault();

        let fd = new FormData()

        setLoading(true);
        await setTimeout(async () => {
            await fd.append("images", image);

            try {

                await api.post('/companies/image/change', fd, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                const owner_company_name = JSON.parse(owner_company).name.split(" ").join('-');

                setLogo("images/" + owner_company_name + "/logo.png");

                toast.current.show({ severity: 'success', summary: '', detail: `Alterado com sucesso, relogue para visualizar`, life: 3000 });

            } catch (e) {

                toast.current.show({ severity: 'error', summary: '', detail: `Houve um erro ao alterar`, life: 3000 });

            }

            setLoading(false);
        }, 2000);



    }

    const changeImageSection = () => {
        return(
            <Grow in={true}>
                <div className="col-md-9 pt-4">
                    <div className="shadow rounded p-2">
                        <span className="fs-4">Alterar logo da empresa</span>
                        <div className={'row'}>
                            <div className={'col-md-12 pb-4 pt-4'}>
                                <form onSubmit={handleUploadImage} className="custom-file" encType="multipart/form-data">
                                    <input type="file" className="custom-file-input" onChange={handleChangeImage} id="customFile" />
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <button type="submit" style={{ width: '100%' }} className="btn btn-primary mt-4">Upload</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Grow>
        );
    }


    const passwordSection = () => {
        return (
            <Grow in={true}>
                <div className="col-md-9 pt-4">
                    <div className="shadow rounded p-2">
                        <span className="fs-4">Trocar senha</span>
                        <form className={'row pt-4'}>


                            <div className="col-md-12 pb-4">
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" type={'password'} value={senha} id="ant-pass" onChange={e => setPassword(e.target.value)} />
                                    <label htmlFor="ant-pass">Senha antiga</label>
                                </span>
                            </div>

                            <div className="col-md-12 pb-4">
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" type={'password'} value={novaSenha} id="new-pass" onChange={e => setNewPassword(e.target.value)} />
                                    <label htmlFor="new-pass">Senha nova</label>
                                </span>
                            </div>

                            <div className="col-md-12 pb-4">
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" type={'password'} value={newPasswordConfirm} id="conf-new-pass" onChange={e => setNewPasswordConfirm(e.target.value)} />
                                    <label htmlFor="conf-new-pass">Confirmar senha nova</label>
                                </span>
                            </div>

                            <div className="col-md-12 d-flex justify-content-center pb-2">
                                <button type="submit" style={{ width: '100%' }} onClick={handleChangePassword} className="btn btn-primary mb-2">Salvar</button>
                            </div>
                            <small>Sua senha deve conter: 8 caracteres, uma letra maiúscula e uma minúscula, um número e um caracter especial</small>
                        </form>
                    </div>

                </div>
            </Grow>
        );
    }

    const myCompaniesSection = () => {
       return(
           <Grow in={true}>
               <div className="col-md-9 pt-4">
                   <div className="shadow rounded p-2">
                       <span className="fs-4">Minhas empresas</span>
                       <TableContainer className={'shadow mt-4'}>
                           <Table size="small" aria-label="a dense table">
                               <TableHead>
                                   <TableRow>
                                       <TableCell>Nome</TableCell>
                                       <TableCell>CNPJ</TableCell>
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                   <TableCell>{owner_companyObject.name}</TableCell>
                                   <TableCell>{cnpjMask(owner_companyObject.cnpj)}</TableCell>
                                   {empresas.map((row) => (
                                       <TableRow key={row.id}>
                                           <TableCell>{row.name}</TableCell>
                                           <TableCell>{cnpjMask(row.cnpj)}</TableCell>
                                       </TableRow>
                                   ))}
                               </TableBody>
                           </Table>
                       </TableContainer>
                   </div>
               </div>
           </Grow>
       );
    }

    const personalDataSection = () => {
        return (
            <Grow in={true}>

                <div className="col-md-9 pt-4">
                    <div className="shadow rounded p-2">
                        <span className="fs-4">Dados pessoais</span>
                        <form className={'row'}>
                            <div className={'col-md-12 pb-4 pt-4'}>
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" id="Nome" value={nome} onChange={ e => setName(e.target.value) }/>
                                    <label htmlFor="Nome">Nome</label>
                                </span>
                            </div>

                            <div className="col-md-6 pb-4">
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" id="E-mail" value={email2} onChange={ e => setEmail(e.target.value) }/>
                                    <label htmlFor="E-mail">E-mail</label>
                                </span>
                            </div>

                            <div className="col-md-6 pb-4">
                                <span className={'p-float-label'}>
                                    <InputMask className="form-control" mask="(99) 9 9999-9999" value={telefone} id="Telefone" onChange={e => setPhone(e.target.value)} />
                                    <label htmlFor="Telefone">Telefone</label>
                                </span>
                            </div>

                            <div className="col-md-6 pb-4">
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" id="Cargo" value={cargo2} onChange={ e => setCargo(e.target.value) }/>
                                    <label htmlFor="Cargo">Cargo</label>
                                </span>
                            </div>

                            <div className="col-md-6 pb-4">
                                <span className={'p-float-label'}>
                                    <InputText className="form-control" id="Setor" value={setor2} onChange={ e => setSetor(e.target.value) }/>
                                    <label htmlFor="Setor">Setor</label>
                                </span>
                            </div>

                            <div className="col-md-12 d-flex justify-content-center pb-4">
                                <button type="submit" style={{ width: '100%' }} onClick={handleEditProfile} className="btn btn-primary mb-2">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Grow>
        );

    }

    return (
        <>
        { <Loading load={loading} /> }
            <Toast ref={toast} position="bottom-right" />

            <div className="row">
                <div className="col-md-3 pt-4">
                    <div className="shadow rounded">
                        <div className="d-flex flex-column p-3">
                                <span className="fs-4">Opções</span>
                            <Divider />
                            <div>
                                <ul className="nav nav-pills flex-column mb-auto">
                                    <li className="nav-item">
                                        <a onClick={() => setActiveSection('inicio')}
                                           className={active === 'inicio' ? 'nav-link active' : "nav-link link-dark"}>
                                            Início
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => setActiveSection('empresas')}
                                           className={active === 'empresas' ? 'nav-link active' : "nav-link link-dark"}>
                                            Minhas empresas
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => setActiveSection('password')}
                                           className={active === 'password' ? 'nav-link active' : "nav-link link-dark"}>
                                            Alterar senha
                                        </a>
                                    </li>
                                    {access_type === 'Admin' &&
                                        <li>
                                            <a onClick={() => setActiveSection('changeImage')}
                                               className={active === 'changeImage' ? 'nav-link active' : "nav-link link-dark"}>
                                                Mudar logo da empresa
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {renderSection()}
            </div>
        </>
    );
}

export default Profile;
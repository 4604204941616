import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import RowsDropdown from "../../../components/RowsDropdown";
import DateRange from "../../../components/ToolbarComponents/DateRange";
import ReportFilters from "../../../components/ReportFilters/ReportFilters";
import { useAuth } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import "./styles.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Table = () => {
  const { access_type } = useAuth();
  const history = useHistory();
  const [rows, setRows] = useState(10);

  const [toastOptions, setToastOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [showFilter, setShowFilter] = useState(false);
  const [multiEmpresas, setMultiEmpresas] = useState([]);
  const [multiTechs, setMultiTechs] = useState([]);
  const onHideFilter = () => setShowFilter(false);

  const [singleChamado, setSingleChamado] = useState({});
  const [singleChamadoModal, setSingleChamadoModal] = useState(false);

  const toast = useRef(null);
  const dt = useRef(null);
  const [preventives, setPreventives] = useState([]);
  const [counter, setCounter] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);

  useEffect(() => {
    getPreventivas();
  }, [
    page,
    search,
    beginDate,
    endDate,
    access_type,
    rows,
    multiEmpresas,
    multiTechs
  ]);

  const getPreventivas = async () => {
    setLoading(true);
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    await api
      .get(
        `preventive/eletric?page=${page}&search=${search}&beginDate=${beginDate}&endDate=${endDate}&rows=${rows}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}`
      )
      .then((response) => {
        setPreventives(response.data.data);
        setCounter(response.data.total);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(toastOptions).length !== 0) {
      toast.current.show({
        severity: toastOptions.severity,
        summary: "",
        detail: toastOptions.detail,
        life: 5000
      });
    }
  }, [toastOptions]);

  const exportExcel = async () => {
    toast.current.show({
      severity: "info",
      summary: "",
      detail: "Gerando excel, aguarde...",
      life: 5000
    });
    setLoading(true);
    const mEmpresas = multiEmpresas.join(",");
    const mTechs = multiTechs.join(",");
    api
      .get(
        `/preventive/export/excel?beginDate=${beginDate}&endDate=${endDate}&search=${search}&multiEmpresas=${mEmpresas}&multiTechs=${mTechs}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const startDate =
          beginDate ||
          new Date(new Date().getFullYear(), 0, 1).toLocaleDateString();
        const finalDate =
          endDate ||
          new Date(new Date().getFullYear(), 11, 31).toLocaleDateString();
        link.setAttribute(
          "download",
          `preventivas-${startDate}-${finalDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail:
            "Ocorreu um erro ao gerar excel, contate um administrador: " +
            error.message,
          life: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportExcel}
        />
      </React.Fragment>
    );
  };

  const downloadReport = async (rowData) => {
    const isGLP =
      rowData.preventive_type === `EmpilhadeiraCombustao` ||
      rowData.preventive_type === `EmpilhadeiraEletricaDeContrapeso`;

    setLoading(true);

    const url = isGLP
      ? "/report/preventive-combustion/pdf/"
      : "/report/preventive/pdf/";

    api
      .get(url + rowData.id, {
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const reportNumber = rowData?.report
          ? rowData?.report.padStart(6, 0)
          : "Sem-Numero-de-Relatorio";
        link.setAttribute("download", reportNumber + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const actionBodyTemplate = (rowData) => {
    const isGLP =
      rowData.preventive_type === `EmpilhadeiraCombustao` ||
      rowData.preventive_type === `EmpilhadeiraEletricaDeContrapeso`;

    const url = isGLP
      ? "preventive-combustion;" + rowData.id
      : "preventive;" + rowData.id;

    return (
      <React.Fragment>
        <Button
          icon="pi pi-file-pdf"
          tooltip="Download"
          tooltipOptions={{ position: "top" }}
          style={{
            backgroundColor: "#7f0b0b",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            const url = isGLP ? "preventive-combustion" : "preventive";
            window.open(`/pdf/${url};${rowData.id}`, "_blank").focus();
          }}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">{"Preventivas"}</h5>
        <span className="p-input-icon-left d-flex">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
          {access_type === "Admin" && (
            <Button
              style={{ color: "white", marginLeft: 8 }}
              icon="pi pi-filter"
              onClick={() => setShowFilter(true)}
            />
          )}
        </span>
      </div>
      <DateRange
        inputBeginDate={inputBeginDate}
        setInputBeginDate={setInputBeginDate}
        beginDate={beginDate}
        setBeginDate={setBeginDate}
        setInputEndDate={setInputEndDate}
        setEndDate={setEndDate}
        inputEndDate={inputEndDate}
        endDate={endDate}
      />
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const renderPreventiveType = (preventiveType) => {
    let typeArr = preventiveType.match(/[A-Z][a-z]+/g);
    if (!typeArr) return preventiveType;
    typeArr.map((type, index) => {
      if (type === "Combustao") {
        typeArr[index] = "Combustão";
      } else if (type === "Eletrica") {
        typeArr[index] = "Elétrica";
      }
    });
    return typeArr.join(" ");
  };

  const constructPhone = (v) => {
    if (v) {
      let r = v.replace(/\D/g, "");
      if (r) {
        r = r.replace(/^0/, "");
        if (r.length > 10) {
          r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
          r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (r.length > 2) {
          r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
          r = r.replace(/^(\d*)/, "($1");
        }
        return r;
      }
    }
    return v;
  };

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast style={{ zIndex: 1101 }} ref={toast} />

        <div className="card">
          <Toolbar className="p-mb-4" right={rightToolbarTemplate} />
          <DataTable
            ref={dt}
            value={preventives}
            dataKey="id"
            rows={rows}
            header={header}
            className={"p-datatable-responsive-demo"}
            loading={loading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column
              headerStyle={{ width: "10rem" }}
              field="report"
              header="Relatório"
              body={(rowData) =>
                rowData.report
                  ? rowData?.report.padStart(6, 0)
                  : "Sem número de relatório"
              }
            />
            <Column field="company.name" header="Empresa" />
            <Column
              field="preventive_type"
              header="Tipo"
              body={(rowData) => renderPreventiveType(rowData.preventive_type)}
            />
            <Column field="equipment.equipment" header="Equipamento" />
            <Column
              field="service_date"
              header="Data"
              body={(rowData) => dataConstruct(rowData.date)}
            />
            <Column field="created_by.name" header="Criado por" />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(counter / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} />
          </div>
        </div>

        <ReportFilters
          setMultiEmpresas={setMultiEmpresas}
          setMultiTechs={setMultiTechs}
          isVisible={showFilter}
          onHide={onHideFilter}
        />

        <Dialog
          header={"Chamado"}
          visible={singleChamadoModal}
          style={{ width: "50vw" }}
          onHide={() => setSingleChamadoModal(false)}
          baseZIndex={1000}
        >
          <div className="container-fluid">
            <div className="row flex-row row-reverse">
              <div className="col-md-6">
                <strong>Chamado: </strong>
                <p>
                  {new Date().getFullYear() + "" + singleChamado.service_number}
                </p>

                <strong>Maquina Parada: </strong>
                <p>{singleChamado.machine_stopped === 0 ? "Não" : "Sim"}</p>

                <strong>Nome empresa: </strong>
                <p>{singleChamado.company?.name}</p>

                <strong>Data do chamado: </strong>
                <p>{dataConstruct(singleChamado.service_date)} </p>

                <strong>Status: </strong>
                <p>{singleChamado.status}</p>

                <strong>Qualidade: </strong>
                <p>{singleChamado.quality}</p>

                <strong>Nome de contato: </strong>
                <p>{singleChamado.company?.contact_name}</p>

                <strong>Telefone: </strong>
                <p>{constructPhone(singleChamado.company?.phone)}</p>

                <strong>Aberto Por: </strong>
                <p>{singleChamado.created_by?.name}</p>
              </div>

              <div className="col-md-6">
                <strong>Descricao do Problema: </strong>
                <p>{singleChamado.error_description}</p>

                <strong>Hora do aviso tecnico </strong>
                <p>{singleChamado.tech_warn_time}</p>

                <strong>Hora do chamado - Cliente </strong>
                <p>{singleChamado.costumer_created_time}</p>

                <strong>Tecnico: </strong>
                <p>{singleChamado.tech?.name}</p>

                <strong>Relatorio: </strong>
                <p>{singleChamado.report_number}</p>

                <strong>Data do Atendimento </strong>
                <p>{dataConstruct(singleChamado.costumer_service_date)}</p>

                <strong>Hora de Atendimento </strong>
                <p>{singleChamado.costumer_service_time}</p>

                <strong>Observações: </strong>
                <p>{singleChamado.comments}</p>

                <strong>Serviço: </strong>
                <p>{singleChamado.service_type}</p>
                <strong>Equipamento: </strong>
                <p>
                  {singleChamado.equipment &&
                    singleChamado.equipment?.equipment +
                      " | " +
                      singleChamado.equipment?.brand?.name +
                      " | " +
                      singleChamado.equipment?.model?.name}
                </p>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default Table;

import { Button } from "primereact/button";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import ContextTable from "../../../components/ContextTable";
import CreatePecasForm from "../../../components/CreatePecasForm";
import { useParts } from "../../../contexts/PartsContext";
import { tableData } from "../../../contexts/PartsContext/ConstData";
import { withPartsContext } from "../../../contexts/PartsContext/withParts";
import api from "../../../services/api";

const PartsTable = () => {
  const context = useParts();

  const [formModal, setFormModal] = useState(false);

  const onHide = () => setFormModal(false);
  const openForm = (rowData = {}) => {
    context.setSelectedItem(rowData);
    setFormModal(true);
  };

  const handleActivePart = async (partId) => {
    context.setLoading(true);
    await api.put(`/parts/update/${partId}`, {
      is_active: true
    });
    context.setLoading(false);
    await context.getFromApi({
      is_active: 0
    });
  };

  const actionTemplate = (rowData) => {
    if (!rowData?.is_active)
      return (
        <Button
          icon="pi pi-chevron-up"
          tooltip="Ativar"
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: "#9E1", outline: 0, border: "none" }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            handleActivePart(rowData.id);
          }}
        />
      );
    return (
      <React.Fragment>
        <Button
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => openForm(rowData)}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Inativar"
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: "#9E3131", outline: 0, border: "none" }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            context.setDeleteDialog(true);
            context.setSelectedItem(rowData);
          }}
        />
      </React.Fragment>
    );
  };

  const renderNumberTemplate = (rowData) => {
    return (
      <NumberFormat
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        prefix="R$ "
        displayType="text"
        allowNegative={false}
        allowLeadingZeros={true}
        value={rowData?.price || 0}
      />
    );
  };

  tableData.find((data) => data.header === "Ações").body = actionTemplate;
  tableData.find((data) => data.header === "Preço").body = renderNumberTemplate;

  const defaultOrderBy = "code";

  return (
    <>
      <ContextTable
        context={context}
        openForm={openForm}
        defaultOrderBy={defaultOrderBy}
        hideForm={onHide}
      />
      <CreatePecasForm onHide={onHide} visible={formModal} />
    </>
  );
};

export default withPartsContext(PartsTable);
